<header class="inner-header">
  <div>
  </div>
  <div class="profile-box" (click)="toggleDropdown()">
    <a
      href="/"
      class="notification d-flex align-items-center mt-2 mb-2 text-decoration-none"
    >
      <img src="assets/wam/notification.png" class="logo img-responsive" />
    </a>
    <div class="welcome">
      <p class="primary">{{userEmail}}</p>
      <p class="secondary">{{firstName}} {{lastName}}</p>
    </div>
    <div class="profile-dropdown" [ngClass]="{ show: isDropdownVisible }">
      <div class="profile-menu">
        <a [routerLink]="['/profile']">
          <img src="assets/wam/menu-profile.png" alt="" />
          <span>Profile</span>
        </a>
        <a (click)="logout()">
          <img src="assets/wam/logout.png" alt="" />
          <span>Log Out</span>
        </a>
      </div>
    </div>
    <a class="d-flex align-items-center mt-2 mb-lg-0 text-decoration-none">
      <img
        [src]="userAvatar ? userAvatar : 'assets/wam/profilepic.png'"
        class="profile logo img-responsive"
      />
      <img src="assets/wam/profile-arrow.png" class="img-responsive" />
    </a>
  </div>
</header>
