import { Component, EventEmitter, OnDestroy, Output, ViewChild, input } from '@angular/core';
import { REGISTER_STEP } from '@app/models/register.step.enum';
import { AuthService } from '@app/services/auth.service';
import { NewUserData } from '@app/types/new.user.details.api.response';
import { VerifyEmailApiResponse, VerifyEmailRequest } from '@app/types/verify.email.api.response';
import { environment } from '@env/environment';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [CodeInputModule],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  @Output() updateStep = new EventEmitter<REGISTER_STEP>();
  @Output() updateData = new EventEmitter<NewUserData>();
  noReplyEmail: string;
  companyName: string;
  token = input.required<string>();
  email = input.required<string>();
  userId = input.required<string>();

  constructor(
    private authService: AuthService,
    private toastService: ToastrService
  ) {
    this.noReplyEmail = environment.NO_REPLY_EMAIL;
    this.companyName = environment.companyName;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  stepBack(): void {
    this.updateRegisterStep(REGISTER_STEP.ENTER_PERSONAL_DETAILS);
  }

  stepNext(): void {
    this.updateRegisterStep(REGISTER_STEP.PAYMENT);
  }

  resendVerificationEmail(): void {
    this.authService.resendVerificationEmail({
      userId: this.userId(),
      token: this.token(),
      email: this.email()
    })
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response:any) => {
      if(response?.success) {
        this.toastService.success(response?.message, response?.title);
      } else {
        this.toastService.error(response?.message, response?.title);
      }
    });
  }

  onCodeCompleted(code: string): void {
    const verificationData: VerifyEmailRequest = {
      code,
      token: this.token(),
      email: this.email(),
      userId: this.userId()
    };
    this.authService.verifyEmailAddress(verificationData)
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response: VerifyEmailApiResponse) => {
      if (response?.success) {
        this.toastService.success(response?.message, response?.title);
        this.stepNext();
      } else {
        this.codeInput.reset();
        this.toastService.error(response?.message, response?.title);
      }
    });
  }

  updateRegisterStep(value: REGISTER_STEP): void {
    this.updateStep.emit(value);
  }
}
