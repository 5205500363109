import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ApiResponse } from '@models/api.response';
import { NewUserDetails } from '@app/types/new.user.details.type';
import { CountriesApiResponse } from '@app/types/countries.api.response.type';
import { NewUserData, NewUserDetailsApiResponse } from '@app/types/new.user.details.api.response';
import { VerifyEmailApiResponse, VerifyEmailRequest } from '@app/types/verify.email.api.response';
import { CreateCheckoutSessionApiResponse, CreateCheckoutSessionRequest } from '@app/types/checkout.session.api.response.type';
import { OnBoardingSessionSuccessApiResponse } from '@app/types/onboarding.session.success.api.response.type';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  api: string;
  public redirectUrl: string = '';
  constructor(private router: Router, private httpClient: HttpClient) {
    this.api = environment.API_URL;
    if (localStorage.getItem('currentUser')) {
      this.currentUserSubject = new BehaviorSubject<any>(
        JSON.parse(localStorage.getItem('currentUser') || '')
      );
    } else {
      this.currentUserSubject = new BehaviorSubject<any>('');
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getCurrentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public setCurrentUserValue(user: any): void {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  login(email: string, password: string): Observable<any> {
    return this.httpClient
      .post<any>(this.api + 'login', { email, password })
      .pipe(
        map((user) => {
          if (user?.data?._jwt?.accessToken) {
            localStorage.setItem(
              'currentUser',
              JSON.stringify(user?.data?._jwt)
            );
            this.currentUserSubject.next(user?.data?._jwt);
            this.router.navigate([user?.data?.redirectTo]);
          }
          return user;
        })
      );
  }

  logout(): Observable<any> {
    // console.log('yes111', localStorage.getItem('currentUser'));

    return this.httpClient.post<any>(this.api + 'logout', {}).pipe(
      map((response) => {
        if (response?.success) {
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null);
          this.router.navigate(['/login']);
        }
        return response;
      })
    );
  }

  getAccessToken(): string {
    const tokenData = JSON.parse(localStorage.getItem('currentUser') || '');
    return tokenData?.accessToken;
  }

  refreshToken(): Observable<any> {
    const tokenDetails = JSON.parse(localStorage.getItem('currentUser') || '');
    const token = { token: tokenDetails?.refreshToken };
    return this.httpClient.post(this.api + '/auth/token', token)
    .pipe(
      map((response: any) => {
        if (response?.success) {
          localStorage.setItem('currentUser', JSON.stringify(response?.data?._jwt));
          this.currentUserSubject.next(response?.data?._jwt);
          this.router.navigate(['/dashboard']);
        } else {
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null);
          this.router.navigate(['/login']);
        }
        return response;
      }),
      catchError(error => {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
        return throwError(error);
      })
    );
  }

  removeRefreshToken(): void {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  sessionTimeOut(): void {
    const redirectURL = 'login';
    localStorage.removeItem('currentUser');
    this.router.navigate([redirectURL]);
  }

  sendRecoveryEmail(user: object): Observable<any> {
    return this.httpClient.post(this.api + '/send-forgot-password-email', user);
  }

  resendRecoveryEmail(user: object): Observable<any> {
    return this.httpClient.post(this.api + '/resend-forgot-password-email', user);
  }

  verifyRecoveryEmail(user: object): Observable<any> {
    return this.httpClient.post(this.api + '/verify-email-for-forgot-password', user);
  }

  resetPassword(passwordData: object): Observable<any> {
    return this.httpClient.post<any>(this.api + '/reset-password', passwordData)
    .pipe(
      map((user) => {
        if (user?.data?._jwt?.accessToken) {
          localStorage.setItem(
            'currentUser',
            JSON.stringify(user?.data?._jwt)
          );
          this.currentUserSubject.next(user?.data?._jwt);
          this.router.navigate(['/dashboard']);
        }
        return user;
      })
    );
  }

  changePassword(data: { newPassword: string, currentPassword: string, confirmNewPassword: string }): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(this.api + 'change-password', data);
  }

  getRecoveryTokenDetails(token: string): Observable<any> {
    return this.httpClient.post<any>(this.api + '/get-recovery-token-details', { token })
  }

  // #region OnBoardingProcess
  getCountries(): Observable<CountriesApiResponse> {
    return this.httpClient.get<CountriesApiResponse>(this.api + 'get-countries');
  }

  saveNewUserDetails(request: NewUserDetails): Observable<NewUserDetailsApiResponse> {
    return this.httpClient.post<NewUserDetailsApiResponse>(this.api + 'save-new-user-details', request);
  }

  resendVerificationEmail(request: NewUserData): Observable<any> {
    return this.httpClient.post<any>(this.api + 'resend-verification-email', request);
  }

  verifyEmailAddress(request: VerifyEmailRequest): Observable<VerifyEmailApiResponse> {
    return this.httpClient.post<VerifyEmailApiResponse>(this.api + 'verify-email-address', request);
  }

  createCheckoutSession(request: CreateCheckoutSessionRequest): Observable<CreateCheckoutSessionApiResponse> {
    return this.httpClient.post<CreateCheckoutSessionApiResponse>(this.api + 'create-checkout-session', request);
  }

  onBoardingSuccess(sessionId: string): Observable<OnBoardingSessionSuccessApiResponse> {
    return this.httpClient.post<OnBoardingSessionSuccessApiResponse>(this.api + 'checkout-session-success', {sessionId})
    .pipe(
      map((user: OnBoardingSessionSuccessApiResponse) => {
        if (user?.data?._jwt?.accessToken) {
          localStorage.setItem(
            'currentUser',
            JSON.stringify(user?.data?._jwt)
          );
          this.currentUserSubject.next(user?.data?._jwt);
          this.router.navigate(['/dashboard']);
        }
        return user;
      })
    );
  }

  // #endregion

}
