<div class="login-page">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6 login-section">
      @if(currentStep === SEND_VERIFICATION_EMAIL) {
      
        <form [formGroup]="forgotPasswordForm" (submit)="submit()">
          <div class="text-center">
            <img
              class="mb-4 login-logo"
              src="assets/register-popup-logo.png"
              alt=""
              width="64"
              height="64"
            />
          </div>
          <h1 class="h3 mb-3 login-heading">Recover your account!</h1>

          <div class="col-12 mb-3">
            <label for="email" class="form-label">Email <span class="text-muted required">*</span></label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="you@example.com"
              formControlName="email"
            />
            @if (forgotPasswordFormControls?.email?.invalid && (forgotPasswordFormControls?.email?.dirty || forgotPasswordFormControls?.email?.touched)) { 
              @if (forgotPasswordFormControls?.email?.errors?.required) {
              <div class="invalid-feedback">Email is required.</div>
              } 
              @if (forgotPasswordFormControls?.email?.errors?.pattern || forgotPasswordFormControls?.email?.errors?.email) {
              <div class="invalid-feedback">Please enter a valid email.</div>
              } 
            }
            @if(forgotPasswordFormControls?.email?.errors?.serverError) {
              <div class="invalid-feedback">{{forgotPasswordFormControls?.email?.errors?.serverError}}</div>
            }
          </div>
          <div class="col-12 mb-3">
            <ngx-recaptcha2 #captchaElem 
              [siteKey]="captchaSiteKey" 
              (reset)="handleCaptchaReset()"
              (expire)="handleCaptchaExpire()" 
              (load)="handleCaptchaLoad()"
              (success)="handleCaptchaSuccess($event)"
              [useGlobalDomain]="captchaSettingGlobalDomain" [size]="'normal'"
              [hl]="captchaSettingLang" [theme]="'light'" [type]="'image'"
              formControlName="recaptcha">
            </ngx-recaptcha2>

            @if (forgotPasswordFormControls?.recaptcha?.invalid && (forgotPasswordFormControls?.recaptcha?.dirty || forgotPasswordFormControls?.recaptcha?.touched)) { 
              @if (forgotPasswordFormControls?.recaptcha?.errors?.required) {
              <div class="invalid-feedback">Captcha is required.</div>
              } 
            }
            @if(forgotPasswordFormControls?.recaptcha?.errors?.serverError) {
              <div class="invalid-feedback">{{forgotPasswordFormControls?.recaptcha?.errors?.serverError}}</div>
            }
          </div>
          <button
            class="w-100 btn btn-primary sign-in-button"
            type="submit"
            [disabled]="forgotPasswordForm.invalid"
          >
            Send email
          </button>
        </form>
     
      }
      @if(currentStep === VERIFY_EMAIL_ADDRESS) {
        <app-verify-forgot-password-email [email]="email()" [token]="token()" (updateStep)="updateCurrentStep($event)" (updateData)="updateData($event)"></app-verify-forgot-password-email>
      }
      @if(currentStep === RESET_PASSWORD) {
        <app-reset-password [email]="email()" [token]="token()" (updateStep)="updateCurrentStep($event)" (updateData)="updateData($event)"></app-reset-password>
      }
    </div>
  </div>
</div>
