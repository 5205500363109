import { Component, OnDestroy, signal } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subject, filter, takeUntil } from 'rxjs';
import { ResetPasswordComponent } from '@controllers/public/forgot-password/reset-password/reset-password.component';
import { FORGOT_PASSWORD_STEP } from '@models/forgot.password.step.enum';

@Component({
  selector: 'app-recovery',
  standalone: true,
  imports: [CommonModule, ResetPasswordComponent],
  templateUrl: './recovery.component.html',
  styleUrl: './recovery.component.scss',
  providers: [HttpClientModule],
})

export class RecoveryComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  currentStep: string = '';
  token = signal<string>('');
  email = signal<string>('');
  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.data.pipe(
      filter((response: any) => !!(response?.tokenDetails?.success && response?.tokenDetails?.data)),
      takeUntil(this.componentDestroyed$)
    ).subscribe((response: any) => {
      this.token.update(() => response?.tokenDetails?.data?.token);
      this.email.update(() => response?.tokenDetails?.data?.email);
    });

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  updateCurrentStep(step: FORGOT_PASSWORD_STEP): void {
    this.currentStep = step;
  }

  updateData({email, token}: {email: string, token: string}): void {
    this.token.update(() => token);
    this.email.update(() => email);
  }

}

