<div class="register-page">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6 register-section">
        <div class="text-center">
          <img
            class="mb-4 register-logo"
            src="assets/register-popup-logo.png"
            alt=""
            width="64"
            height="64"
          />
        </div>
        <h1 class="h3 mb-3 login-heading">Please check your email</h1>
        <div class="col-12 mb-3">
          <div class="field mb-2">
            <p>
              To verify your email, please check your email for a message from
              <a href="mailto:{{ noReplyEmail }}">{{ companyName }}</a> and
              enter the code we have sent to:
              <span class="bold">{{ email() }}</span
              >.
            </p>
          </div>
          <div class="field mb-2">
            <label for="email" class="mb-2"
              >Verification code <span class="required">*</span></label
            >
            <code-input
              [isCodeHidden]="false"
              [codeLength]="6"
              (codeCompleted)="onCodeCompleted($event)"
              style="font-size: 24px"
              formControlName="code"
            >
            </code-input>
          </div>
          <div class="field mt-3 mb-3">
            <p>
              Please check your spam folder if you do not see the verification
              email in your inbox. If you still do not see it, click "Resend
              code."
            </p>
          </div>
        </div>
        <button
          class="btn btn-primary float-start"
          type="button"
          (click)="resendVerificationEmail()"
        >
          Resend code
        </button>
      </div>
    </div>
  </div>
</div>
