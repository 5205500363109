import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JobberService } from '@services/jobber.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-jobber-connect',
  standalone: true,
  imports: [],
  template: ``,
  styles: ``
})
export class JobberConnectComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  constructor(
    private router: Router,
    private routerAt: ActivatedRoute,
    private toastrService: ToastrService,
    private jobberService: JobberService
  ) {
    const queryParams = this.routerAt.snapshot.queryParams;
    if(queryParams?.['code'] && queryParams?.['state']) {
      this.jobberService.exchangeCode(queryParams?.['code'], queryParams?.['state']).pipe(
        takeUntil(this.componentDestroyed$)
      ).subscribe((response: any) => {
        if(response?.success) {
          this.toastrService.success(response?.message, response?.title);
        } else {
          this.toastrService.error(response?.message, response?.title);
        }
        this.router.navigate(['/integrations/jobber']);
      });
    } else {
      this.toastrService.error('Access denied', 'Jobber connect!');
      this.router.navigate(['/integrations/jobber']);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
