import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '@controllers/public/header/header.component';
import { FooterComponent } from '@controllers/public/footer/footer.component';
import { PrivacyPolicyComponent } from '@controllers/public/privacy-policy/privacy-policy.component';
import { TermOfServiceComponent } from '@controllers/public/term-of-service/term-of-service.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'app-base',
    standalone: true,
    templateUrl: './base.component.html',
    styleUrl: './base.component.scss',
    providers: [],
    imports: [FormsModule, RouterOutlet, HeaderComponent, FooterComponent, PrivacyPolicyComponent, TermOfServiceComponent],
})
export class BaseComponent {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {
        const userDetails = this.authService.getCurrentUserValue()
        if (userDetails) {
            this.router.navigate(['/dashboard']);
        }
    }

}
