import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import dayjs from 'dayjs/esm';
import { MaterialModule } from '@material/material.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { UserService } from '@services/user.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { DateRanges, TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DrillDownService } from '@services/drill-down.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-date-range',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MaterialModule,
    NgxDaterangepickerMd
  ],
  templateUrl: './date-range.component.html',
  styleUrl: './date-range.component.scss',
})
export class DateRangeComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  selectedDateRange: TimePeriod;
  minDate: dayjs.Dayjs;
  maxDate: dayjs.Dayjs;
  ranges: DateRanges = {
    ['Today']: [dayjs(), dayjs()],
    ['Yesterday']: [dayjs().subtract(1, 'days'), dayjs().subtract(1, 'days')],
    ['Last 7 Days']: [dayjs().subtract(6, 'days'), dayjs()],
    ['Last 30 Days']: [dayjs().subtract(29, 'days'), dayjs()],
    ['This Month']: [dayjs().startOf('month'), dayjs().endOf('month')],
    ['Last Month']: [
      dayjs().subtract(1, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
    ['Last 3 Month']: [
      dayjs().subtract(3, 'month').startOf('month'),
      dayjs().subtract(1, 'month').endOf('month'),
    ],
  };

  constructor(
    private userService: UserService,
    private drillDownService: DrillDownService,
    private toastrService: ToastrService
  ) {
    this.selectedDateRange = {
      startDate: dayjs().startOf('year'),
      endDate: dayjs(),
    };
    this.minDate = dayjs().subtract(2, 'years');
    this.maxDate = dayjs();
    // this.fetchData(this.selectedDateRange);
  }

  ngOnInit(): void {
    this.userService.getUserLastSelectedDateRange()
    .pipe(
      filter(response => !!response?.data),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response: any) => {
      this.selectedDateRange = {
        startDate: dayjs(response?.data?.dateRange?.startDate),
        endDate: dayjs(response?.data?.dateRange?.endDate)
      };
      this.fetchData(this.selectedDateRange);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  datesUpdated(range: TimePeriod): void {
    console.log('🚀 ~ DateRangeComponent ~ datesUpdated ~ range:', range)
    this.fetchData(range);
  }

  fetchData(range: TimePeriod): void {
    if (range?.startDate && range?.endDate) {
      console.log('🚀 ~ DateRangeComponent ~ fetchData ~ range:', range)
      this.userService.updateFetchedState('pending');
      this.drillDownService
        .fetchSelectedDateRangeData(range)
        .pipe(
          filter(response => !!response),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe((response: any) => {
          if (response?.success) {
            this.toastrService.success(response?.message, response?.title);
            this.userService.updateSelectedDateRange(range);
            this.userService.updateUserLastSelectedDateRange(range).subscribe();
            this.userService.updateFetchedState('fetched');
          } else {
            this.toastrService.error(response?.message, response?.title);
          }
        });
    }
  }
}
