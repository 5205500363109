<div class="toolbox-body-top">
  <h4 class="m-0">{{integration}}</h4>
  @if (!lastSuccessSync) {
    <h6>Seems like you never sync with {{integration}}, Try sync now! </h6>
  }
  @else {
    <h6>Last Synced: {{ lastSuccessSync?.syncedDetails?.endedAt | date: 'MMM d, y, h:mm:ss a' }}</h6>
  }
</div>
<hr/>
<div class="row app-sync-tab mt-3">
  <div class="app-sync-list-wrapper col-md-6">
    <ul class="app-sync-list app-qbo">
      <li class="app-sync-li app-card app-card-solid app-shadow-low">
        <div class="app-li-title">
          <strong>Invoices</strong>
        </div>
        @if (isInvoicesLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <div class="app-li-description mb-2">
          @if (!latestSuccessSyncLogsData?.invoices) {
            <small>Invoices never synced</small>
          }
          @else {
            <small>Last Synced: {{ latestSuccessSyncLogsData?.invoices?.syncedDetails?.endedAt | date : "MMM d, y, h:mm:ss a" }}</small>
          }
        </div>
        <button mat-raised-button (click)="syncInvoices()" [disabled]="isInvoicesLoading || !isJobberConnected">
          Sync Now
        </button>
        @if (isInvoicesLoading) {
          <button mat-icon-button (click)="onCancel('invoices')" class="float-right" color="warn" matTooltip="Force Stop">
            <mat-icon>block</mat-icon>
          </button>
        }
      </li>
      <li class="app-sync-li app-card app-card-solid app-shadow-low">
        <div class="app-li-title">
          <strong>Requests</strong>
        </div>
        @if (isRequestsLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <div class="app-li-description mb-2">
          @if (!latestSuccessSyncLogsData?.invoices) {
            <small>Requests never synced</small>
          }
          @else {
            <small>Last Synced: {{ latestSuccessSyncLogsData?.requests?.syncedDetails?.endedAt | date : "MMM d, y, h:mm:ss a" }}</small>
          }
        </div>
        <button mat-raised-button (click)="syncRequests()" [disabled]="isRequestsLoading || !isJobberConnected">
          Sync Now
        </button>
        @if (isRequestsLoading) {
          <button mat-icon-button (click)="onCancel('requests')" class="float-right" color="warn" matTooltip="Force Stop">
            <mat-icon>block</mat-icon>
          </button>
        }
      </li>
      <li class="app-sync-li app-card app-card-solid app-shadow-low">
        <div class="app-li-title">
          <strong>Visits</strong>
        </div>
        @if (isVisitsLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <div class="app-li-description mb-2">
          @if (!latestSuccessSyncLogsData?.visits) {
            <small>Visits never synced</small>
          }
          @else {
            <small>Last Synced: {{ latestSuccessSyncLogsData?.visits?.syncedDetails?.endedAt | date : "MMM d, y, h:mm:ss a" }}</small>
          }
        </div>
        <button mat-raised-button (click)="syncVisits()" [disabled]="isVisitsLoading || !isJobberConnected">
          Sync Now
        </button>
        @if (isVisitsLoading) {
          <button mat-icon-button (click)="onCancel('visits')" class="float-right" color="warn" matTooltip="Force Stop">
            <mat-icon>block</mat-icon>
          </button>
        }
      </li>
      <li class="app-sync-li app-card app-card-solid app-shadow-low">
        <div class="app-li-title">
          <strong>TimeSheets</strong>
        </div>
        @if (isTimeSheetsLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <div class="app-li-description mb-2">
          @if (!latestSuccessSyncLogsData?.timeSheets) {
            <small>TimeSheets never synced</small>
          }
          @else {
            <small>Last Synced: {{ latestSuccessSyncLogsData?.timeSheets?.syncedDetails?.endedAt | date : "MMM d, y, h:mm:ss a" }}</small>
          }
        </div>
        <button mat-raised-button (click)="syncTimeSheets()" [disabled]="isTimeSheetsLoading || !isJobberConnected">
          Sync Now
        </button>
        @if (isTimeSheetsLoading) {
          <button mat-icon-button (click)="onCancel('timeSheets')" class="float-right" color="warn" matTooltip="Force Stop">
            <mat-icon>block</mat-icon>
          </button>
        }
      </li>
    </ul>
  </div>
  <div class="app-connect-wrapper col-md-6">
    @if (isJobberConnected) {
      <p><b>Company Name:</b> {{JobberCompanyName}}</p>
      <p><b>Company ID:</b> {{jobberCompanyId}}</p>
      <button mat-raised-button color="primary" (click)="disconnectJobber()" class="app-img-btn app-btn-jobber-disconnect">
        Disconnect from Jobber
      </button>
    }
    @else  {
      <button mat-raised-button color="primary" (click)="connectJobber()" class="app-img-btn app-btn-jobber-connect">
        Connect Jobber
      </button>
    }
  </div>
</div>
