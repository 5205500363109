<main class="app-main">
    <section class="app-banner pricing-page">
        <div class="">
            <div class="row mt-4 mb-4">
                <p class="col-md-12 your-plan-details-p">Your plan</p>
                <div class="col-md-9 your-plan-details">
                    <div class="box row m-0">
                        <div class="item pl-0 expand-width-20">
                            <p class="item-name">Plan name</p>
                            <span class="item-value">{{currentPlanName | titlecase}}</span>
                        </div>
                        <div class="item expand-width-20">
                            <p class="item-name">Billing cycle</p>
                            <span class="item-value">Per {{currentBillingCycle}}</span>
                        </div>
                        
                            <div class="item expand-width-20 last-item">
                                <p class="item-name">Total</p>
                                <span class="item-value">{{currentPlanTotal | currency: 'USD'}}</span>
                        </div>
                            <div class="item pr-0 expend-width">
                                <p class="item-name">Status</p>
                                <span class="{{currentPlanStatus}}-status" *ngIf="!isPaused && currentPlanStatus !== 'trialing'">{{currentPlanStatus | titlecase}}</span>
                                <span class="{{currentPlanStatus}}-status" *ngIf="!isPaused && currentPlanStatus === 'trialing'">Trial ends {{trialEndAt | date: 'MMM dd'}}</span>
                                <span class="paused-status" *ngIf="isPaused && (currentPlanStatus === 'active' || currentPlanStatus === 'trialing')">Paused</span>
                                <span class="canceled-status" *ngIf="isPaused && currentPlanStatus === 'canceled'">Canceled</span>
                            </div>
                    </div>
                </div>
                <div class="col-md-3">
                    @if(currentPlanStatus) {
                        <div class="btn-group-vertical float-right">
                            <button class="btn mb-3 btn-primary" *ngIf="isPaused && (currentPlanStatus === 'active' || currentPlanStatus === 'trialing')" (click)="resumeSubscription()">Resume subscription</button>
                            <button class="btn mb-3 btn-primary" (click)="updateCard()">Update card</button>
                        </div>
                    }
                </div>

            </div>
            <div class="row" *ngIf="currentPlanStatus !== 'canceled'">
                <div class="col-md-12">
                    <hr>
                </div>
                <div class="col-md-9">
                    Don’t need {{companyName}} right now? You can cancel or pause your subscription.
                </div>
                <div class="col-md-3">
                    <div class="btn-group-vertical float-right">
                        <button class="btn btn-primary mb-3" [routerLink]="['/cancel-subscription']">Cancel subscription</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>