import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';
import { Observable, map } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PricingPlansResolverService implements Resolve<any> {

  constructor(
    private userService: UserService,
    private router: Router,
    private toastService: ToastrService
  ) { }

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<object> | Observable<never> | Observable<any> {
    return this.userService.getPricingPlansList()
    .pipe(
      map((response: any) => {
        if (response?.success) {
          return response;
        } else {
          this.router.navigate(['/login']);
          this.toastService.error(response?.message, response?.title);
        }
      })
    );
  }
}
