<div class="menu-container sidebar" [ngClass]="{ thinner: !isExpanded }">
  <div class="text-center">
    <div class="menubar-box toggle-icon" (click)="toggleExpanded()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
  </div>
  <div *ngIf="!isExpanded" class="sidebar-logo-small">
    <img src="assets/logo_mini.svg" alt="" />
  </div>
  <div *ngIf="isExpanded" class="sidebar-logo">
    <img src="assets/logo_white.svg" class="img-responsive" alt="" />
  </div>
  @if (!hideFeatures) {
    <a class="active" [routerLink]="['/dashboard']">
      <img src="assets/wam/menu-dashboard.png" alt="" />
      <span *ngIf="isExpanded">Dashboard</span>
    </a>
    <a [routerLink]="['/integrations']">
      <img src="assets/wam/menu-integration.png" alt="" />
      <span *ngIf="isExpanded">Integrations</span>
    </a>
  }
  

  <!-- <a [routerLink]="['/profile']">
    <img src="assets/wam/menu-profile.png" alt="" />
    <span *ngIf="isExpanded">Profile</span>
  </a> -->

  <a [routerLink]="['/billing']">
    <img src="assets/wam/menu-billing.png" alt="" />
    <span *ngIf="isExpanded">Billing</span>
  </a>

  

  <a>
    <img src="assets/wam/help.png" alt="" />
    <span *ngIf="isExpanded">Help</span>
  </a>

  @if (isAdmin) {
  <a [routerLink]="['/admin/add-client']">
    <img src="assets/wam/add-user.png" alt="" />
    <span *ngIf="isExpanded">Add Client</span>
  </a>
  }

  <!-- <a>
    <img src="assets/wam/add-user.png" alt="" />
    <span *ngIf="isExpanded">Add Users</span>
  </a>

  <a (click)="logout()">
    <img src="assets/wam/logout.png" alt="" />
    <span *ngIf="isExpanded">Log Out</span>
  </a> -->
</div>
