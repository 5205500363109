import * as i0 from '@angular/core';
import { signal, Injectable, Directive, Input, HostListener, effect, booleanAttribute, Component, ChangeDetectionStrategy } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import * as i2 from '@fortawesome/angular-fontawesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgClass } from '@angular/common';

class ShowHideService {
    constructor() {
        this.states = [];
    }
    getIO(id) {
        let io = this.states.find((o) => o.id === id);
        if (!io) {
            io = this.init(id);
        }
        return io;
    }
    init(id) {
        // const subject = new ReplaySubject<boolean>(1);
        const subject = signal(false);
        const io = {
            id,
            show: false,
            subject,
        };
        this.states.push(io);
        return io;
    }
    saveAndProadcast(io, show) {
        io.show = show;
        io.subject.update((value) => io.show);
    }
    getSignal(id) {
        return this.getIO(id).subject;
    }
    setShow(id, show) {
        this.saveAndProadcast(this.getIO(id), show);
    }
    toggleShow(id) {
        const io = this.getIO(id);
        this.saveAndProadcast(io, !io.show);
    }
    static { this.ɵfac = function ShowHideService_Factory(t) { return new (t || ShowHideService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ShowHideService, factory: ShowHideService.ɵfac, providedIn: 'root' }); }
}
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShowHideService, [{
        type: Injectable,
        args: [{
                providedIn: 'root',
            }]
    }], () => [], null); })();

class ShowHideTriggerDirective {
    constructor(service, errorHandler) {
        this.service = service;
        this.errorHandler = errorHandler;
    }
    onClick() {
        this.service.toggleShow(this.showHideTrigger);
    }
    static { this.ɵfac = function ShowHideTriggerDirective_Factory(t) { return new (t || ShowHideTriggerDirective)(i0.ɵɵdirectiveInject(ShowHideService), i0.ɵɵdirectiveInject(i0.ErrorHandler)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ShowHideTriggerDirective, selectors: [["", "showHideTrigger", ""]], hostBindings: function ShowHideTriggerDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("click", function ShowHideTriggerDirective_click_HostBindingHandler() { return ctx.onClick(); });
        } }, inputs: { showHideTrigger: "showHideTrigger" }, standalone: true }); }
}
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShowHideTriggerDirective, [{
        type: Directive,
        args: [{
                // eslint-disable-next-line @angular-eslint/directive-selector
                selector: '[showHideTrigger]',
                standalone: true,
            }]
    }], () => [{ type: ShowHideService }, { type: i0.ErrorHandler }], { showHideTrigger: [{
            type: Input,
            args: [{ required: true }]
        }], onClick: [{
            type: HostListener,
            args: ['click']
        }] }); })();

/* eslint-disable @angular-eslint/directive-selector */
const defaultConfig = {
    show: 'visibility',
    hide: 'visibility_off',
    materialIcon: false,
};
// TODO: don't trigger on disabled element
class ShowHideStatusDirective {
    set showHideStatus(config) {
        this.init(config);
    }
    constructor(service, el, renderer, errorHandler, injector) {
        this.service = service;
        this.el = el;
        this.renderer = renderer;
        this.errorHandler = errorHandler;
        this.injector = injector;
        this.config = defaultConfig;
    }
    init(config) {
        this.config = {
            ...defaultConfig,
            ...config,
        };
        if (this.config.id) {
            effect(() => {
                this.updateStatus(this.service.getSignal(this.config.id)());
            }, { injector: this.injector });
        }
        else {
            this.errorHandler.handleError(new Error(`Status can not be set without [id].`));
        }
    }
    updateStatus(show) {
        if (this.config.materialIcon) {
            this.renderer.setProperty(this.el.nativeElement, 'innerHTML', show ? this.config.hide : this.config.show);
        }
        else {
            this.renderer.removeClass(this.el.nativeElement, (!show ? this.config.hide : this.config.show) ?? '');
            this.renderer.addClass(this.el.nativeElement, (show ? this.config.hide : this.config.show) ?? '');
        }
    }
    static { this.ɵfac = function ShowHideStatusDirective_Factory(t) { return new (t || ShowHideStatusDirective)(i0.ɵɵdirectiveInject(ShowHideService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ErrorHandler), i0.ɵɵdirectiveInject(i0.Injector)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ShowHideStatusDirective, selectors: [["", "showHideStatus", ""]], inputs: { showHideStatus: "showHideStatus" }, standalone: true }); }
}
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShowHideStatusDirective, [{
        type: Directive,
        args: [{
                selector: '[showHideStatus]',
                standalone: true,
            }]
    }], () => [{ type: ShowHideService }, { type: i0.ElementRef }, { type: i0.Renderer2 }, { type: i0.ErrorHandler }, { type: i0.Injector }], { showHideStatus: [{
            type: Input,
            args: [{ required: true }]
        }] }); })();

class ShowHideInputDirective {
    constructor(service, el, renderer, injector) {
        this.service = service;
        this.el = el;
        this.renderer = renderer;
        this.injector = injector;
        this.disabled = false;
    }
    ngOnInit() {
        this.service.setShow(this.id, this.el.nativeElement.type !== 'password');
        effect(() => {
            if (this.disabled)
                return;
            this.renderer.setAttribute(this.el.nativeElement, 'type', this.service.getSignal(this.id)() ? 'text' : 'password');
        }, { injector: this.injector });
    }
    static { this.ɵfac = function ShowHideInputDirective_Factory(t) { return new (t || ShowHideInputDirective)(i0.ɵɵdirectiveInject(ShowHideService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.Injector)); }; }
    static { this.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ShowHideInputDirective, selectors: [["input", "showHideInput", ""]], inputs: { id: "id", disabled: ["disabled", "disabled", booleanAttribute] }, standalone: true, features: [i0.ɵɵInputTransformsFeature] }); }
}
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShowHideInputDirective, [{
        type: Directive,
        args: [{
                // eslint-disable-next-line @angular-eslint/directive-selector
                selector: 'input[showHideInput]',
                standalone: true,
            }]
    }], () => [{ type: ShowHideService }, { type: i0.ElementRef }, { type: i0.Renderer2 }, { type: i0.Injector }], { id: [{
            type: Input,
            args: [{ required: true }]
        }], disabled: [{
            type: Input,
            args: [{ transform: booleanAttribute }]
        }] }); })();

/* eslint-disable no-bitwise */
const _c0 = a0 => ({ id: a0 });
const _c1 = ["*"];
var BtnStyle;
(function (BtnStyle) {
    BtnStyle["Primary"] = "primary";
    BtnStyle["Secondary"] = "secondary";
    BtnStyle["Success"] = "success";
    BtnStyle["Danger"] = "danger";
    BtnStyle["Warning"] = "warning";
    BtnStyle["Info"] = "info";
    BtnStyle["Dark"] = "dark";
    BtnStyle["Light"] = "light";
})(BtnStyle || (BtnStyle = {}));
// hail jed https://gist.github.com/jed/982883
const uuid = (a) => a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
/**
 * Add a split input button to password or text input. Toggles input type between "text" and "password".
 *
 * @example
 * <show-hide-password size="sm|lg">
 * <input type="password" name=... />
 * </show-hide-password>
 */
class ShowHidePasswordComponent {
    constructor(service, elem, renderer, injector) {
        this.service = service;
        this.elem = elem;
        this.renderer = renderer;
        this.injector = injector;
        this.btnStyle = BtnStyle.Secondary;
        this.btnOutline = true;
        this.faEye = faEye;
        this.faEyeSlash = faEyeSlash;
    }
    ngOnInit() {
        this.input = this.elem.nativeElement.querySelector('input');
        if (!this.input) {
            throw new Error(`No input element found.`);
        }
        this.id = this.input.getAttribute('id');
        if (!this.id) {
            this.id = 'showHideInput_' + uuid();
            this.renderer.setAttribute(this.input, 'id', this.id);
        }
        this.renderer.addClass(this.elem.nativeElement, 'input-group');
        if (this.size === 'sm') {
            this.renderer.addClass(this.elem.nativeElement, 'input-group-sm');
        }
        else if (this.size === 'lg') {
            this.renderer.addClass(this.elem.nativeElement, 'input-group-lg');
        }
        this.isHidden = this.input.type === 'password';
        this.renderer.addClass(this.input, 'form-control'); // just to be sure
        this.service.setShow(this.id, this.input.type !== 'password');
        effect(() => {
            const show = this.service.getSignal(this.id)();
            this.isHidden = !show;
            this.renderer.setAttribute(this.input, 'type', show ? 'text' : 'password');
        }, { injector: this.injector });
    }
    static { this.ɵfac = function ShowHidePasswordComponent_Factory(t) { return new (t || ShowHidePasswordComponent)(i0.ɵɵdirectiveInject(ShowHideService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.Injector)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ShowHidePasswordComponent, selectors: [["show-hide-password"]], inputs: { btnStyle: "btnStyle", btnOutline: "btnOutline", size: "size" }, standalone: true, features: [i0.ɵɵStandaloneFeature], ngContentSelectors: _c1, decls: 3, vars: 7, consts: [["type", "button", 1, "btn", "ngx-show-hide-password", 3, "ngClass", "showHideTrigger"], ["size", "lg", 3, "fixedWidth", "icon", "showHideStatus"]], template: function ShowHidePasswordComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵprojectionDef();
            i0.ɵɵprojection(0);
            i0.ɵɵelementStart(1, "button", 0);
            i0.ɵɵelement(2, "fa-icon", 1);
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("ngClass", ctx.btnOutline ? "btn-outline-" + ctx.btnStyle : "btn-" + ctx.btnStyle)("showHideTrigger", ctx.id);
            i0.ɵɵadvance(1);
            i0.ɵɵproperty("fixedWidth", true)("icon", ctx.isHidden ? ctx.faEye : ctx.faEyeSlash)("showHideStatus", i0.ɵɵpureFunction1(5, _c0, ctx.id));
        } }, dependencies: [NgClass, ShowHideTriggerDirective, FontAwesomeModule, i2.FaIconComponent, ShowHideStatusDirective], encapsulation: 2, changeDetection: 0 }); }
}
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShowHidePasswordComponent, [{
        type: Component,
        args: [{
                // eslint-disable-next-line @angular-eslint/component-selector
                selector: 'show-hide-password',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: `
    <ng-content></ng-content>
    <button
      class="btn ngx-show-hide-password"
      [ngClass]="btnOutline ? 'btn-outline-' + btnStyle : 'btn-' + btnStyle"
      type="button"
      [showHideTrigger]="id"
    >
      <fa-icon
        [fixedWidth]="true"
        size="lg"
        [icon]="isHidden ? faEye : faEyeSlash"
        [showHideStatus]="{ id: id }"
      ></fa-icon>
    </button>
  `,
                standalone: true,
                imports: [NgClass, ShowHideTriggerDirective, FontAwesomeModule, ShowHideStatusDirective],
            }]
    }], () => [{ type: ShowHideService }, { type: i0.ElementRef }, { type: i0.Renderer2 }, { type: i0.Injector }], { btnStyle: [{
            type: Input
        }], btnOutline: [{
            type: Input
        }], size: [{
            type: Input
        }] }); })();
(() => { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(ShowHidePasswordComponent, { className: "ShowHidePasswordComponent" }); })();

/*
 * Public API Surface of ngx-show-hide-password
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BtnStyle, ShowHideInputDirective, ShowHidePasswordComponent, ShowHideService, ShowHideStatusDirective, ShowHideTriggerDirective };

