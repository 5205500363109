<div class="register-page">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6 register-section">
          <div class="text-center">
            <img
              class="mb-4 register-logo"
              src="assets/register-popup-logo.png"
              alt=""
              width="64"
              height="64"
            />
          </div>
          <div class="p-3 bg-light bg-opacity-10">
            <h6 class="card-title mb-3">Summary</h6>
            <div class="d-flex justify-content-between mb-1 small">
              <span>Plan</span> <span>{{selectedPlan()?.name}}</span>
            </div>
            <div class="d-flex justify-content-between mb-1 small">
              <span>Billing cycle</span> <span>{{selectedPlan()?.billingCycle}}</span>
            </div>
            @if(selectedPaymentType() === 'FreeTrial') {
            <div class="d-flex justify-content-between mb-1 small">
              <span>Free trial</span> <span>{{selectedPlan()?.noOfTrialDays}} days</span>
            </div>
            }
            <hr>
            <div class="d-flex justify-content-between mb-4 small">
              <span>TOTAL</span> <strong class="text-dark">{{selectedPlan()?.planPrice | currency : 'USD'}}</strong>
            </div>
          </div>
          <hr>
          <h1 class="h3 mb-3 register-heading">Enter personal details</h1>
          <form [formGroup]="registerForm" (submit)="submitHandler()">
          <div class="row">
            <div class="col-6 mb-3">
              <label for="firstName" class="form-label">First name <span class="text-muted required">*</span></label>
              <input type="text" class="form-control" formControlName="firstName"/>
              @if (registerFormControls?.firstName?.invalid && (registerFormControls?.firstName?.dirty || registerFormControls?.firstName?.touched)) { 
                @if (registerFormControls?.firstName?.errors?.required) {
                <div class="invalid-feedback">First name is required.</div>
                } 
                @if (registerFormControls?.firstName?.errors?.pattern) {
                <div class="invalid-feedback">Please enter a valid first name.</div>
                } 
              }
              @if(registerFormControls?.firstName?.errors?.serverError) {
                <div class="invalid-feedback">{{registerFormControls?.firstName?.errors?.serverError}}</div>
              }
            </div>
            <div class="col-6 mb-3">
              <label for="firstName" class="form-label">Last name <span class="text-muted required">*</span></label>
              <input type="text" class="form-control" formControlName="lastName"/>
              @if (registerFormControls?.lastName?.invalid && (registerFormControls?.lastName?.dirty || registerFormControls?.lastName?.touched)) { 
                @if (registerFormControls?.lastName?.errors?.required) {
                <div class="invalid-feedback">Last name is required.</div>
                } 
                @if (registerFormControls?.lastName?.errors?.pattern) {
                <div class="invalid-feedback">Please enter a valid last name.</div>
                } 
            }
            @if(registerFormControls?.lastName?.errors?.serverError) {
              <div class="invalid-feedback">{{registerFormControls?.lastName?.errors?.serverError}}</div>
            }
            </div>
          </div>
  
          <div class="row">
            <div class="col-6 mb-3">
              <label for="buinessName" class="form-label">Company name <span class="text-muted required">*</span></label>
              <input type="text" class="form-control"  placeholder="" formControlName="companyName"/>
              @if (registerFormControls?.companyName?.invalid && (registerFormControls?.companyName?.dirty || registerFormControls?.companyName?.touched)) { 
                @if (registerFormControls?.companyName?.errors?.required) {
                <div class="invalid-feedback">Company name is required.</div>
                }
              }
              @if(registerFormControls?.companyName?.errors?.serverError) {
                <div class="invalid-feedback">{{registerFormControls?.companyName?.errors?.serverError}}</div>
              }
            </div>
            <div class="col-6 mb-3">
              <label for="country" class="form-label">Country <span class="text-muted required">*</span></label>
              <select class="form-control" formControlName="country">
                  <option>Select Country</option>
                  @for (country of countries; track $index) {
                  <option [value]="country?.countryName">{{ country?.countryName }}</option>
                  }
              </select>
              @if (registerFormControls?.country?.invalid && (registerFormControls?.country?.dirty || registerFormControls?.country?.touched)) { 
                  @if (registerFormControls?.country?.errors?.required) {
                  <div class="invalid-feedback">Phone is required.</div>
                  }
              }
              @if(registerFormControls?.country?.errors?.serverError) {
              <div class="invalid-feedback">{{registerFormControls?.country?.errors?.serverError}}</div>
              }
          </div>
          </div>
  
          <div class="row">
            <div class="col-6 mb-3">
              <label for="phone" class="form-label">Phone <span class="text-muted required">*</span></label>
              <input type="text" class="form-control" formControlName="phone" />
              @if (registerFormControls?.phone?.invalid && (registerFormControls?.phone?.dirty || registerFormControls?.phone?.touched)) { 
                @if (registerFormControls?.phone?.errors?.required) {
                <div class="invalid-feedback">Phone is required.</div>
                }
            }
            @if(registerFormControls?.phone?.errors?.serverError) {
              <div class="invalid-feedback">{{registerFormControls?.phone?.errors?.serverError}}</div>
            }
            </div>
  
            <div class="col-6 mb-3">
              <label for="email" class="form-label">Email <span class="text-muted required">*</span></label>
              <input type="email" class="form-control" formControlName="email" autocomplete="no" />
              @if (registerFormControls?.email?.invalid && (registerFormControls?.email?.dirty || registerFormControls?.email?.touched)) { 
                @if (registerFormControls?.email?.errors?.required) {
                <div class="invalid-feedback">Email is required.</div>
                } 
                @if (registerFormControls?.email?.errors?.pattern) {
                <div class="invalid-feedback">Please enter a valid email.</div>
                } 
              }
              @if(registerFormControls?.email?.errors?.serverError) {
                <div class="invalid-feedback">{{registerFormControls?.email?.errors?.serverError}}</div>
              }
            </div>
          </div>
  
          <div class="row">
            <div class="col-6 mb-3">
              <label for="firstName" class="form-label">Password <span class="text-muted required">*</span></label>
              <show-hide-password class="show-hide-btn" [btnOutline]="false">
                <input type="password" class="form-control" formControlName="password" autocomplete="false"/>
              </show-hide-password>
              @if(registerFormControls?.password?.invalid && (registerFormControls?.password?.dirty || registerFormControls?.password?.touched)) {
                @if(registerFormControls?.password?.errors?.required) {
                    <div class="invalid-feedback">Password is required</div>
                }
              }
              @if(registerFormControls?.password?.errors?.serverError) {
                <div class="invalid-feedback">{{registerFormControls?.password?.errors?.serverError}}</div>
              }
            </div>
  
            <div class="col-6 mb-3">
              <label for="confirmPassword" class="form-label">Confirm password<span class="text-muted required">*</span></label>
              <show-hide-password class="show-hide-btn" [btnOutline]="false">
                <input type="password" class="form-control" formControlName="confirmPassword" autocomplete="false"/>
              </show-hide-password>
              @if(registerFormControls?.confirmPassword?.invalid && (registerFormControls?.confirmPassword?.dirty || registerFormControls?.confirmPassword?.touched)) {
                @if(registerFormControls?.confirmPassword?.errors?.required) {
                    <div class="invalid-feedback">Confirm password is required</div>
                }
              }
              @if(registerFormControls?.confirmPassword?.errors?.serverError) {
                <div class="invalid-feedback">{{registerFormControls?.confirmPassword?.errors?.serverError}}</div>
              }
            </div>
          </div>
          @if(registerFormControls.password.dirty || registerFormControls.confirmPassword.dirty) {
            <div class="col-12 mb-3">
                <label class="password-requirements">Password Requirements:</label>
                <ul class="password-requirement">
                    <li [ngClass]="registerFormControls?.password?.errors?.required || registerFormControls.password.errors?.hasSmallCase?'password-requirement-invalid':'password-requirement-valid'">
                        At least one lowercase letter (a-z).
                    </li>
                    <li [ngClass]="registerFormControls?.password?.errors?.required || registerFormControls.password.errors?.hasCapitalCase?'password-requirement-invalid':'password-requirement-valid'">
                        At least one UPPERCASE letter (A-Z).
                    </li>
                    <li [ngClass]="registerFormControls?.password?.errors?.required || registerFormControls.password.errors?.hasNumber?'password-requirement-invalid':'password-requirement-valid'">
                        At least one number (0-9).
                    </li>
                    <li
                        [ngClass]="(registerFormControls || null)?.password?.errors?.required || registerFormControls.password.errors?.hasSpecialCharacters?'password-requirement-invalid':'password-requirement-valid'">
                        At least one special character.
                    </li>
                    <li
                        [ngClass]="registerFormControls?.password?.errors?.required || (registerFormControls.password.errors?.minlength || registerFormControls.password.errors?.maxlength)?'password-requirement-invalid':'password-requirement-valid'">
                        Has length of 8-64 characters.
                    </li>
                    <li
                        [ngClass]="registerFormControls?.password?.errors?.required || registerFormControls.confirmPassword.errors?.NoPasswordMatch?'password-requirement-invalid':'password-requirement-valid'">
                        Please confirm your password.
                    </li>
                </ul>
            </div>
        }
          <button type="button" class="btn btn-primary mt-2 float-start" (click)="stepBack()">Change plan</button>
          <button class="btn btn-primary mt-2 float-end" type="submit" [disabled]="registerForm.invalid">Next</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  