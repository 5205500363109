import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const curUser = this.authService.getCurrentUserValue();
    const routeRoles = route.data['roles'];
    const url: string = state.url;
    if (!curUser) {
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = url;
      this.toastr.warning('Sorry, you do not have access to this route', 'Access Denied!>>>>>>>>>>>>>>');

      // Navigate to the login page with extras
      this.router.navigate(['/login']);
      return false;
    } else {
      if (!routeRoles || routeRoles.length === 0) {
        return true;
      } else {
        if(curUser?.loginUser?.roles) {
            if (routeRoles.some((item: any) => curUser?.loginUser?.roles.includes(item))) {
                console.log('🚀 ~ AuthGuard ~ canActivate ~ routeRoles:', routeRoles)
                // role not authorized so redirect to home page
                return true;
              } else {
                // logged in so return true
                this.toastr.warning('Sorry, you do not have access to this route', 'Access Denied!');
                this.router.navigate(['/']);
                return false;
              }
        } else {
            this.toastr.warning('Sorry, you do not have access to this route', 'Access Denied!');
            this.router.navigate(['/']);
            return false;
        }
      }
    }
  }
  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const curUser = this.authService.getCurrentUserValue();
      const routeRoles = route.data['roles'];
      if (curUser) {
          if (!routeRoles || routeRoles.length === 0) {
            return true;
          } else {
            for (const role of curUser?.roles || []) {
              // check if route is restricted by role
              if (routeRoles && routeRoles.indexOf(role) > -1) {
                // role not authorized so redirect to home page
                return true;
              }
            }
          }
          // logged in so return true
          this.toastr.warning('You are not authorized to this route.', 'Unauthorized User!');
          this.router.navigate(['/']);
          return false;
      } else {
        return false;
      }
  }
  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
