import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  api: string;
  constructor(private httpClient: HttpClient) {
    this.api = environment.API_URL;
  }

  createClient(user: object): Observable<any> {
    return this.httpClient.post(this.api + 'admin/create-client', user);
  }
}
