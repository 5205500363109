<div class="home-page">
  <div class="container">
    <div class="row">
      <div class="col-md-6 heading-section">
        <h1>
          Business <br />Dashboard <br />Software For<br />
          Contractor
        </h1>

        <a class="btn btn-primary try-for-free-btn"
          >Try for free <img class="arrow-right" src="assets/arrow-right-circel.png"
        /></a>
      </div>
      <div class="col-md-6 graph-section">
        <img class="img-responsive" src="assets/charts.png" />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12 pt-3 pb-4">
        <h4 class="testimonials-heading">- Testimonials</h4>
        <h2 class="testimonials-heading-1">Don't take our word for it</h2>
        <div class="row">
          <div class="col-md-3 testimonial">
            <h4 class="testimonial-title">Placeholder1</h4>
            <p class="testimonial-description">
              1, Please provide some content for this. Let me know if we need to hide this one.
            </p>
            <div class="row">
              <div class="col-md-3">
                <img
                  class="testimonial-image img-responsive rounded"
                  src="assets/avatars/3.png"
                />
              </div>
              <div class="col-md-9">
                <h4 class="testimonial-name">Sophia Moore</h4>
                <h5 class="testimonial-designation">Placeholder2</h5>
              </div>
            </div>
          </div>
          <div class="col-md-3 testimonial">
            <h4 class="testimonial-title">Placeholder3</h4>
            <p class="testimonial-description">
              2, Please provide some content for this. Let me know if we need to hide this one.
            </p>
            <div class="row">
              <div class="col-md-3">
                <img
                  class="testimonial-image img-responsive rounded"
                  src="assets/avatars/2.png"
                />
              </div>
              <div class="col-md-9">
                <h4 class="testimonial-name">Adam Smith</h4>
                <h5 class="testimonial-designation">Placeholder4</h5>
              </div>
            </div>
          </div>
          <div class="col-md-3 testimonial">
            <h4 class="testimonial-title">Placeholder5</h4>
            <p class="testimonial-description">
              3, Please provide some content for this. Let me know if we need to hide this one.
            </p>
            <div class="row">
              <div class="col-md-3">
                <img
                  class="testimonial-image img-responsive rounded"
                  src="assets/avatars/1.png"
                />
              </div>
              <div class="col-md-9">
                <h4 class="testimonial-name">Mike Warren</h4>
                <h5 class="testimonial-designation">
                  Placeholder6
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
