<div class="app-container">
  <table mat-table [dataSource]="appLogsDataSource" class="app-table" #outerSort="matSort" multiTemplateDataRows matSort matSortActive="Date" matSortDirection="asc" matSortDisableClear (matSortChange)="announceSortChange($event)">
    <ng-container matColumnDef="#">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-5">#</th>
      <td mat-cell *matCellDef="let element" class="w-5">
        {{ element?.logId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Synced With">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">
        Synced With
      </th>
      <td mat-cell *matCellDef="let element" class="w-15">
        {{ element?.syncedWith }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Data Type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">
        Data Type
      </th>
      <td mat-cell *matCellDef="let element" class="w-15">
        {{ element?.syncedDetails?.dataType }}
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-15">
        Status
      </th>
      <td mat-cell *matCellDef="let element" class="w-15">
        <span *ngIf="element?.status === 'STARTED'" class="app-status app-status-running">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </span>
        <span *ngIf="element?.status === 'SUCCESS'" class="app-status app-status-success">
          <mat-icon>check_circle</mat-icon> Success
        </span>
        <span *ngIf="element?.status === 'FAIL_JOBBER' || element?.status === 'FAIL_QBO' || element?.status === 'FAIL_STAGING'" class="app-status app-status-fail">
          <mat-icon>cancel</mat-icon> Failed
        </span>
        <span *ngIf="element?.status === 'STOPPED' || element?.status === 'ERROR'" class="app-status app-status-fail">
          <mat-icon>block</mat-icon> Stopped
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-25">
        Sync Date
      </th>
      <td mat-cell *matCellDef="let element" class="w-25">
        {{ element?.syncedDetails?.startedAt | date : "MMM d, y, h:mm a" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="logsColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: logsColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    [length]="totalLogs"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    (page)="onPageChange($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
