<div class="col-md-12 mt-4">
    <form [formGroup]="passwordForm" class="form" (submit)="onSubmitPassword()">
        <div class="row">
            <div class="col-12 col-sm-6 mb-3">
                <div class="mb-2 mt-2"><b>Change Password</b></div>
                <div class="row">
                    <div class="col mt-2">
                        <label for="cuurentPassowrd" class="form-label">Current password <span class="text-muted required">*</span></label>
                        <show-hide-password class="show-hide-btn" [btnOutline]="false">
                            <input class="form-control" type="password" formControlName="currentPassword"/>
                        </show-hide-password>
                        @if(passwordControls?.currentPassword?.invalid && (passwordControls?.currentPassword?.dirty || passwordControls?.currentPassword?.touched)) {
                            @if(passwordControls?.currentPassword?.errors?.required) {
                                <div class="invalid-feedback">Current password is required</div>
                            }
                        }
                        @if(passwordControls?.currentPassword?.errors?.serverError) {
                            <div class="invalid-feedback">{{passwordControls?.currentPassword?.errors?.serverError}}</div>
                        }
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-2">
                        <label for="newPassowrd" class="form-label">New password <span class="text-muted required">*</span></label>
                        <show-hide-password class="show-hide-btn" [btnOutline]="false">
                            <input class="form-control" type="password" formControlName="newPassword"/>
                        </show-hide-password>
                        @if(passwordControls?.newPassword?.invalid && (passwordControls?.newPassword?.dirty || passwordControls?.newPassword?.touched)) {
                            @if(passwordControls?.newPassword?.errors?.required) {
                                <div class="invalid-feedback">New password is required</div>
                            }
                        }
                        @if(passwordControls?.newPassword?.errors?.serverError) {
                            <div class="invalid-feedback">{{passwordControls?.newPassword?.errors?.serverError}}</div>
                        }
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-2">
                        <label for="confirmPassowrd" class="form-label">Confirm password <span class="text-muted required">*</span></label>
                        <show-hide-password class="show-hide-btn" [btnOutline]="false">
                            <input class="form-control" type="password" formControlName="confirmPassword"/>
                        </show-hide-password>
                        @if(passwordControls?.confirmPassword?.invalid && (passwordControls?.confirmPassword?.dirty || passwordControls?.confirmPassword?.touched)) {
                            @if(passwordControls?.confirmPassword?.errors?.required) {
                                <div class="invalid-feedback">Confirm password is required</div>
                            }
                        }
                        @if(passwordControls?.confirmPassword?.errors?.serverError) {
                            <div class="invalid-feedback">{{passwordControls?.confirmPassword?.errors?.serverError}}</div>
                        }
                    </div>
                </div>
            </div>
            @if(passwordControls.newPassword.dirty || passwordControls.confirmPassword.dirty) {
                <div class="col-12 mb-3">
                    <label class="password-requirements">Password Requirements:</label>
                    <ul class="password-requirement">
                        <li [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasSmallCase?'password-requirement-invalid':'password-requirement-valid'">
                            At least one lowercase letter (a-z).
                        </li>
                        <li [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasCapitalCase?'password-requirement-invalid':'password-requirement-valid'">
                            At least one UPPERCASE letter (A-Z).
                        </li>
                        <li [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasNumber?'password-requirement-invalid':'password-requirement-valid'">
                            At least one number (0-9).
                        </li>
                        <li
                            [ngClass]="(passwordControls || null)?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasSpecialCharacters?'password-requirement-invalid':'password-requirement-valid'">
                            At least one special character.
                        </li>
                        <li
                            [ngClass]="passwordControls?.newPassword?.errors?.required || (passwordControls.newPassword.errors?.minlength || passwordControls.newPassword.errors?.maxlength)?'password-requirement-invalid':'password-requirement-valid'">
                            Has length of 8-64 characters.
                        </li>
                        <li
                            [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.confirmPassword.errors?.NoPasswordMatch?'password-requirement-invalid':'password-requirement-valid'">
                            Please confirm your password.
                        </li>
                    </ul>
                </div>
            }
            <div class="row">
                <div class="col d-flex justify-content-end mt-2">
                    <button class="btn btn-primary" [disabled]="passwordForm.invalid" type="submit">Save Changes</button>
                </div>
            </div>
        </div>
    </form>
</div>