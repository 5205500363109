<div class="grid-container">
  <app-date-range></app-date-range>
  <div class="col-md-12">
    <div class="data-grid">
      <div class="row grid-row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Gross Profit Margin')">
            @if (grossProfitMargin === null) { Incomplete }
            @else {
            {{grossProfitMargin | currency : defaultCurrency}}
            }
          </p>
          <p class="col-data" (click)="updateGraph('Gross Profit Margin')">Gross Profit Margin</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Gross Profit Margin</p>
          }
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Revenue Per Labor Hour')">
            @if (revenuePerLaborHour === null) { Incomplete }
            @else {
            {{revenuePerLaborHour | currency : defaultCurrency}}
            }
          </p>
          <p class="col-data" (click)="updateGraph('Revenue Per Labor Hour')">Revenue Per Labor Hour</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Revenue Per Labor Hour</p>
          }
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Average Visit Revenue')">
            @if (averageVisitRevenue === null) { Incomplete }
            @else {
            {{averageVisitRevenue | currency : defaultCurrency}}
            }
          </p>
          <p class="col-data" (click)="updateGraph('Average Visit Revenue')">Average Visit Revenue</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Average Visit Revenue</p>
          }
        </div>
      </div>
      <div class="row grid-row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Average Profit Per Visit')">
            @if (averageProfitPerVisit === null) { Incomplete }
            @else {
            {{averageProfitPerVisit | currency : defaultCurrency}}
            }
          </p>
          <p class="col-data" (click)="updateGraph('Average Profit Per Visit')">Average Profit Per Visit</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Average Profit Per Visit</p>
          }
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Visits Completed')">{{visitsCompleted}}</p>
          <p class="col-data" (click)="updateGraph('Visits Completed')">Visits Completed</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Visits Completed</p>
          }
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Average Visits Per Customer')">
            @if (averageVisitsPerCustomer === null) { Incomplete }
            @else {
            {{averageVisitsPerCustomer}}
            }
          </p>
          <p class="col-data" (click)="updateGraph('Average Visits Per Customer')">Average Visits Per Customer</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Average Visits Per Customer</p>
          }
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Average Customer Lifetime Profit')">
            @if (averageCustomerLifetimeProfit === null) { Incomplete }
            @else {
            {{averageCustomerLifetimeProfit | currency : defaultCurrency}}
            }
          </p>
          <p class="col-data" (click)="updateGraph('Average Customer Lifetime Profit')">Average Customer Lifetime Profit</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Average Customer Lifetime Profit</p>
          }
        </div>
      </div>
    </div>
    @defer (when !fetchingInProgress) {
    <app-bar-graph
      [defaultSelectedGraph]="defaultSelectedGraph()"
    ></app-bar-graph>
    } @placeholder {
    <div class="bar-graph">
      <img
        class="img-responsive bar-graph-loader"
        src="assets/bar-graph-placeholder.png"
      />
    </div>
    }
  </div>
</div>
