import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class JobberService {
  api: string;
  constructor(
    private httpClient: HttpClient
  ) {
    this.api = environment.API_URL;
  }

  connect(): Observable<any> {
    return this.httpClient.get<any>(this.api + '/connect-jobber');
  }

  exchangeCode(code: string, state: string): Observable<any> {
    return this.httpClient.post<any>(this.api + '/jobber-exchange-oauth-code', { code, state});
  }

  disconnect(): Observable<any> {
    return this.httpClient.post<any>(this.api + '/disconnect-jobber', {});
  }

  getJobberDetails(): Observable<any> {
    return this.httpClient.get<any>(this.api + '/get-jobber-details');
  }

  stopSync(request: any): Observable<any> {
    return this.httpClient.post<any>(this.api + '/stop-jobber-sync', request);
  }

  syncInvoices(): Observable<any> {
    return this.httpClient.post<any>(this.api + '/sync-jobber-invoices', {});
  }

  syncRequests(): Observable<any> {
    return this.httpClient.post<any>(this.api + '/sync-jobber-requests', {});
  }

  syncVisits(): Observable<any> {
    return this.httpClient.post<any>(this.api + '/sync-jobber-visits', {});
  }

  syncTimeSheets(): Observable<any> {
    return this.httpClient.post<any>(this.api + '/sync-jobber-time-sheets', {});
  }
}
