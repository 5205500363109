import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '@services/user.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsResolverService implements Resolve<any> {

  constructor(
    private userService: UserService
  ) { }

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<object> | Observable<never> | Observable<any> {
    return this.userService.getUserDetails();
  }
}
