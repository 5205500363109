import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingApiResponse, BillingDetails } from '@app/types/billing.api.response';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  api: string;
  private billingDetails = new BehaviorSubject<any>({});
  getBillingDetails = this.billingDetails.asObservable();
  constructor(
    private httpClient: HttpClient
  ) {
    this.api = environment.API_URL;
  }

  getBillingDetailsByApi(): Observable<BillingApiResponse> {
    return this.httpClient.post<BillingApiResponse>(this.api + 'get-billing-details', {});
  }

  refreshBillingDetails(billingDetails: BillingDetails): any{
    this.billingDetails.next(billingDetails);
  }
}
