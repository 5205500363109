import { Component, EventEmitter, OnDestroy, Output, input } from '@angular/core';
import { Subject, catchError, takeUntil, throwError } from 'rxjs';
import { FORGOT_PASSWORD_STEP } from '@models/forgot.password.step.enum';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ShowHideInputDirective, ShowHidePasswordComponent, ShowHideStatusDirective, ShowHideTriggerDirective } from 'ngx-show-hide-password';
import { CustomValidators } from '@helpers/custom-validators';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule,  ShowHidePasswordComponent, ShowHideInputDirective, ShowHideStatusDirective, ShowHideTriggerDirective],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  @Output() updateStep = new EventEmitter<FORGOT_PASSWORD_STEP>();
  @Output() updateData = new EventEmitter<{email: string, token: string}>();
  token = input.required<string>();
  email = input.required<string>();
  passwordForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastrService
  ) {
    this.passwordForm = this.formBuilder.group({
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(/[~@#$%^&*+=_`|{}:;!.?"()[]/ , { hasSpecialCharacters: true })
      ]
    ],
      confirmPassword: ['', Validators.required]
    }, {
      validator: CustomValidators.newPasswordMatchValidator
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  get passwordControls(): any {
    return this.passwordForm.controls;
  }

  resetPassword(): void {
    if(this.passwordForm.valid) {
      this.authService.resetPassword({
        password: this.passwordForm?.controls?.['newPassword']?.value,
        confirmPassword: this.passwordForm?.controls?.['confirmPassword']?.value,
        token: this.token()
      })
      .pipe(
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.passwordForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response: any) => {
        if(response?.success) {
          this.toastService.success(response?.message, response?.title);
        } else {
          this.toastService.error(response?.message, response?.title);
        }
      });
    }
  }

  updateForgotPassStep(value: FORGOT_PASSWORD_STEP): void {
    this.updateStep.emit(value);
  }

  updateForgotPassData(email: string, token: string): void {
    this.updateData.emit({email, token});
  }
}
