import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@services/user.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { UserProfileComponent } from '@controllers/private/profile/user-profile/user-profile.component';
import { UserSecurityComponent } from '@controllers/private/profile/user-security/user-security.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [MatTabsModule, UserProfileComponent, UserSecurityComponent],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProfileComponent implements OnDestroy {
  userAvatar: string = '';
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private toastService: ToastrService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {
    this.route.data
    .pipe(
      filter((response: any) => !!response?.userDetails?.data),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((data:any) => {
      this.userAvatar = data?.userDetails?.data?.userAvatar;
    });
  }

  onUserAvatarFileChange(event: any): void {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.userAvatar = reader.result as string;
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          this.userService.updateProfileImage(reader.result)
          .pipe(
            takeUntil(this.componentDestroyed$)
          )
          .subscribe((response: any) => {
            this.toastService.success(response?.message, response?.title);
            this.userService.reloadUserDetail(true);
          });
        };
      };
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
