import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '@services/auth.service';
import { RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@services/user.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { BillingDetails } from '@app/types/billing.api.response';
import { BillingService } from '@app/services/billing.service';

@Component({
  selector: 'app-inner-sidebar',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './inner-sidebar.component.html',
  styleUrl: './inner-sidebar.component.scss',
})
export class InnerSidebarComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  isAdmin: boolean;
  hideFeatures: boolean;
  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastService: ToastrService,
    private billingService: BillingService
  ) {
    this.isAdmin = false;
    this.hideFeatures = true;
  }

  ngOnInit(): void {
    this.userService.getUserDetails()
      .pipe(
        filter(response => response?.success),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data: any) => {
        this.isAdmin = data?.data?.roles?.includes('Admin');
      });

      this.billingService.getBillingDetails
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((billingDetails: BillingDetails) => {
        this.hideFeatures = billingDetails?.status !== 'trialing' && billingDetails?.status !== 'active' || billingDetails?.isPaused;
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  logout(): void {
    this.authService.logout()
    .pipe(
      filter(response => response?.success),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((result) => {
      this.toastService.success(result?.message, result?.title);
    });
  }

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
    this.toggleSidebar.emit(this.isExpanded);
  }
}
