import { Component, OnInit } from '@angular/core';
import { MaterialModule } from '@material/material.module';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-integrations',
  standalone: true,
  imports: [CommonModule, MaterialModule, RouterModule],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss'
})
export class IntegrationsComponent implements OnInit {
  private componentDestroyed$: Subject<void> = new Subject();
  activeTab: string;
  showQuickBooks: boolean;
  showJobber: boolean;
  isQBOConnected: boolean;
  isJobberConnected: boolean;

  constructor(
    private route: ActivatedRoute
  ) {
    this.activeTab = 'All';
    this.showQuickBooks = true;
    this.showJobber = true;
    this.isQBOConnected = false;
    this.isJobberConnected = false;
  }

  ngOnInit(): void {
    this.route.data.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((data: any) => {
      this.isQBOConnected = data?.integrations?.data?.isQBOConnected;
      this.isJobberConnected = data?.integrations?.data?.isJobberConnected;
    });
  }

  onChangeTab(tab: string): void {
    this.activeTab = tab;
    if (this.activeTab === 'Connected') {
      this.showQuickBooks = this.isQBOConnected;
      this.showJobber = this.isJobberConnected;
    } else if (this.activeTab === 'Disconnected') {
      this.showQuickBooks = !this.isQBOConnected;
      this.showJobber = !this.isJobberConnected;
    } else {
      this.showQuickBooks = true;
      this.showJobber = true;
    }
  }

}
