<div class="register-page">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        @if(currentStep === 'SELECT_PLAN') {
        <div class="container">
            <div class="text-center">
                <div class="nav price-tabs" role="tablist">
                    <a class="nav-link active" href="#monthly" role="tab" data-bs-toggle="tab">Monthly</a>
                    <a class="nav-link" href="#yearly" role="tab" data-bs-toggle="tab">Yearly</a>
                </div>
            </div>
            <div class="tab-content wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                <div role="tabpanel" class="tab-pane fade" id="yearly">
                    <div class="row justify-content-center">
                        @for (item of yearlyPlan(plans()); track $index) {
                        <div class="col-md-6 col-lg-4 mb-30">
                            <div class="price-item text-center">
                                <div class="price-top">
                                    <h4>{{item.name}}</h4>
                                    <h2 class="mb-0"><sup>$</sup>{{item.planPrice}}</h2>
                                    <span class="text-capitalize">per year</span>
                                </div>
                                <div class="price-content">
                                    <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                        @for (featureItem of item.features; track $index) {
                                        <li>
                                            <i class="zmdi zmdi-check mr-2"></i>
                                            <span class="c-black">{{featureItem}}</span>
                                        </li>
                                        }
                                    </ul>
                                    @if(item.isFreeTrialEnable) {
                                        <a (click)="selectPlan(item, 'FreeTrial')" class="btn btn-primary me-2">Free trial ({{item.noOfTrialDays}} days)</a>
                                    }
                                    <a (click)="selectPlan(item, 'BuyNow')" class="btn btn-primary">Buy now</a>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div class="required text-center">Note: * Credit card details are required for free trial</div>
                </div>
                <div role="tabpanel" class="tab-pane fade show active" id="monthly">
                    <div class="row justify-content-center">
                        @for (item of monthlyPlan(plans()); track $index) {
                            <div class="col-md-6 col-lg-4 mb-30">
                                <div class="price-item text-center">
                                    <div class="price-top">
                                        <h4>{{item.name}}</h4>
                                        <h2 class="mb-0"><sup>$</sup>{{item.planPrice}}</h2>
                                        <span class="text-capitalize">per month</span>
                                    </div>
                                    <div class="price-content">
                                        <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                            @for (featureItem of item.features; track $index) {
                                            <li>
                                                <i class="zmdi zmdi-check mr-2"></i>
                                                <span class="c-black">{{featureItem}}</span>
                                            </li>
                                            }
                                        </ul>
                                        @if(item.isFreeTrialEnable) {
                                            <a (click)="selectPlan(item, 'FreeTrial')" class="btn btn-primary me-2">Free trial ({{item.noOfTrialDays}} days)</a>
                                        }
                                        <a (click)="selectPlan(item, 'BuyNow')" class="btn btn-primary">Buy now</a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="required text-center">Note: * Credit card details are required for free trial</div>
                </div>
            </div>
        </div>
        }
       @if(currentStep === 'ENTER_PERSONAL_DETAILS') {
        <app-new-user-details [selectedPlan]="selectedPlan()" [selectedPaymentType]="selectedPaymentType()" (updateStep)="updateCurrentStep($event)" (updateData)="updateData($event)"></app-new-user-details>
       }
       @if(currentStep === 'VERIFY_EMAIL') {
        <app-verify-email [token]="token()" [userId]="userId()" [email]="email()" (updateStep)="updateCurrentStep($event)" (updateData)="updateData($event)"></app-verify-email>
       }
       @if(currentStep === 'PAYMENT') {
        <app-payment-hosted-page [selectedPlan]="selectedPlan()" [selectedPaymentType]="selectedPaymentType()" [userId]="userId()" [email]="email()"></app-payment-hosted-page>
       }
      </div>
    </div>
  </div>
  