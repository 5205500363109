<div class="fx-date-range-container">
  <input
    class="fx-date-range"
    type="text"
    placeholder="Select Date Range"
    matInput
    ngxDaterangepickerMd
    [locale]="{ applyLabel: 'Ok', format: 'MMMM DD, YYYY' }"
    [drops]="'down'"
    [opens]="'right'"
    [ranges]="ranges"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [showCustomRangeLabel]="true"
    [alwaysShowCalendars]="true"
    [keepCalendarOpeningWithRange]="true"
    [showCancel]="true"
    (datesUpdated)="datesUpdated($event)"
    autocomplete="off"
    [(ngModel)]="selectedDateRange"
    name="daterange"
  />
  <label for="calendar-input" class="date-range-picker-icon fa fa-calendar"></label>
</div>
