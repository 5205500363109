import { Component, EventEmitter, OnDestroy, Output, ViewChild, input } from '@angular/core';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';
import { FORGOT_PASSWORD_STEP } from '@models/forgot.password.step.enum';

@Component({
  selector: 'app-verify-forgot-password-email',
  standalone: true,
  imports: [CodeInputModule],
  templateUrl: './verify-forgot-password-email.component.html',
  styleUrl: './verify-forgot-password-email.component.scss'
})
export class VerifyForgotPasswordEmailComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  @Output() updateStep = new EventEmitter<FORGOT_PASSWORD_STEP>();
  @Output() updateData = new EventEmitter<{email: string, token: string}>();
  noReplyEmail: string;
  companyName: string;
  token = input.required<string>();
  email = input.required<string>();

  constructor(
    private authService: AuthService,
    private toastService: ToastrService
  ) {
    this.noReplyEmail = environment.NO_REPLY_EMAIL;
    this.companyName = environment.companyName;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  changeEmail(): void {
    this.updateForgotPassStep(FORGOT_PASSWORD_STEP.SEND_VERIFICATION_EMAIL);
  }

  resendPasswordResetEmail(): void {
    this.authService.resendRecoveryEmail({email: this.email(), token: this.token()})
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response:any) => {
      if(response?.success) {
        this.toastService.success(response?.message, response?.title);
        this.updateForgotPassData(response?.data?.email, response?.data?.token);
      } else {
        this.toastService.error(response?.message, response?.title);
      }
    });
  }

  onCodeCompleted(code: string): void {
    const verificationData = {
      code,
      token: this.token(),
      email: this.email()
    };
    this.authService.verifyRecoveryEmail(verificationData)
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response:any) => {
      if (response?.success) {
        this.toastService.success(response?.message, response?.title);
        this.updateForgotPassData(response?.data?.email, response?.data?.token);
        this.updateForgotPassStep(FORGOT_PASSWORD_STEP.RESET_PASSWORD);
      } else {
        this.codeInput.reset();
        this.toastService.error(response?.message, response?.title);
      }
    });
  }

  updateForgotPassStep(value: FORGOT_PASSWORD_STEP): void {
    this.updateStep.emit(value);
  }

  updateForgotPassData(email: string, token: string): void {
    this.updateData.emit({email, token});
  }
}
