import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { Subject, filter, takeUntil } from 'rxjs';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-inner-header',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.scss'],
})
export class InnerHeaderComponent implements OnInit {
  private componentDestroyed$: Subject<void> = new Subject();
  userEmail: string;
  firstName: string;
  lastName: string;
  userAvatar: string;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private toastrService: ToastrService,
    private route: ActivatedRoute
  ) {
    this.userEmail = '';
    this.firstName = '';
    this.lastName = '';
    this.userAvatar = '';
  }
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();

  isExpanded: boolean = false;
  isDropdownVisible: boolean = false;

  ngOnInit(): void {
    this.getUser();
    this.userService.getReloadUserDetailsObservable()
    .pipe(
      filter(response => !!response),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(() => {
      this.userService.getUserDetails()
      .pipe(
        filter(response => !!(response?.success && response?.data)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((data: any) => {
        this.userEmail = data?.data?.email;
        this.firstName = data?.data?.firstName;
        this.lastName = data?.data?.lastName;
        this.userAvatar = data?.data?.userAvatar;
      });
    });
  }

  getUser(): void {
    this.route.data.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((data: any) => {
      this.userEmail = data?.userDetails?.data?.email;
      this.firstName = data?.userDetails?.data?.firstName;
      this.lastName = data?.userDetails?.data?.lastName;
      this.userAvatar = data?.userDetails?.data?.userAvatar;
    });
  }

  logout(): void {
    // console.log('yes');
    this.authService.logout().subscribe((result) => {
      // console.log('yes--result-->', result);
      this.toastrService.success(result?.message, result?.title);
    });
  }
  toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
    this.toggleSidebar.emit(this.isExpanded);
  }
}
