import { Routes } from '@angular/router';
import { BaseComponent } from '@controllers/public/base/base.component';
import { HomeComponent } from '@controllers/public/home/home.component';
import { LoginComponent } from '@controllers/public/login/login.component';
import { PrivacyPolicyComponent } from '@controllers/public/privacy-policy/privacy-policy.component';
import { TermOfServiceComponent } from '@controllers/public/term-of-service/term-of-service.component';
import { PrivateBaseComponent } from '@controllers/private/private-base/private-base.component';
import { DashboardComponent } from '@controllers/private/dashboard/dashboard.component';
import { SalesMarketingComponent } from '@controllers/private/sales-marketing/sales-marketing.component';
import { IntegrationsComponent } from '@controllers/private/integrations/integrations.component';
import { AddUserComponent } from '@controllers/private/add-user/add-user.component';
import { ProfileComponent } from '@controllers/private/profile/profile.component';
import { BillingComponent } from '@controllers/private/billing/billing.component';
import { QuickBooksComponent } from '@controllers/private/integrations/quick-books/quick-books.component';
import { AuthGuard } from '@helpers/auth.guard';
import { FinancialsComponent } from '@controllers/private/financials/financials.component';
import { OperationsComponent } from '@controllers/private/operations/operations.component';
import { JobCostingComponent } from '@controllers/private/job-costing/job-costing.component';
import { UserDetailsResolverService } from '@services/user-details-resolver.service';
import { IntegrationResolverService } from '@services/integration-resolver.service';
import { ForgotPasswordComponent } from '@controllers/public/forgot-password/forgot-password.component';
import { RecoveryComponent } from '@controllers/public/recovery/recovery.component';
import { JobberComponent } from '@controllers/private/integrations/jobber/jobber.component';
import { JobberConnectComponent } from '@controllers/private/integrations/jobber/jobber-connect.component';
import { IntegrationChildComponent } from '@controllers/private/integrations/integration-child/integration-child.component';
import { QuickBooksConnectComponent } from '@controllers/private/integrations/quick-books/quick-books-connect.component';
import { AddClientComponent } from '@controllers/private/admin/add-client/add-client.component';
import { AllClientsComponent } from '@controllers/private/admin/all-clients/all-clients.component';
import { RecoveryResolverService } from '@services/recovery.resolver.service';
import { PricingComponent } from './controllers/public/pricing/pricing.component';
import { PricingPlansResolverService } from './services/pricing.plans.resolver.service';
import { OnBoardingSuccessResolverService } from './services/on-boarding-success-resolver.service';
import { OnboardingSuccessComponent } from './controllers/public/pricing/onboarding-success/onboarding-success.component';
import { UpdateCardSuccessComponent } from './controllers/private/update-card-success/update-card-success.component';
import { UpdateCardDetailsResolverService } from './services/update-card-details-resolver.service';
import { CancelSubscriptionComponent } from './controllers/private/billing/cancel-subscription/cancel-subscription.component';
import { BillingDetailsResolverService } from './services/billing-details-resolver.service';
import { QuickBooksDisconnectComponent } from './controllers/public/quick-books-disconnect/quick-books-disconnect.component';
import { JobberDisconnectComponent } from './controllers/public/jobber-disconnect/jobber-disconnect.component';
import { QuickbooksDisconnectResolverService } from './services/quickbooks.disconnect.resolver.service';

export const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'recovery',
        component: RecoveryComponent,
        resolve: { tokenDetails: RecoveryResolverService }
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'term-of-service',
        component: TermOfServiceComponent,
      },
      {
        path: 'onboarding',
        component: PricingComponent,
        resolve: {pricingPlansList: PricingPlansResolverService}
      },
      {
        path: 'onboarding/success',
        component: OnboardingSuccessComponent,
        resolve: {onBoardingSuccess: OnBoardingSuccessResolverService}
      },
      {
        path: 'integrations/quick-books/disconnect',
        component: QuickBooksDisconnectComponent,
        resolve: {qboDisconnectResolver: QuickbooksDisconnectResolverService}
      },
      {
        path: 'integrations/Jobber/disconnect',
        component: JobberDisconnectComponent
      }
    ],
  },
  {
    path: '',
    component: PrivateBaseComponent,
    canActivate: [AuthGuard],
    resolve: {
      userDetails: UserDetailsResolverService,
      billingDetails: BillingDetailsResolverService
    },
    children: [
      {
        path: 'dashboard',
        data: { roles: ['Customer'] },
        component: DashboardComponent,
      },
      {
        path: 'integrations',
        resolve: { integrations: IntegrationResolverService },
        component: IntegrationsComponent,
      },
      {
        path: 'integrations',
        component: IntegrationChildComponent,
        children: [
          {
            path: 'quick-books',
            resolve: { integrations: IntegrationResolverService },
            data: {integration: 'QuickBooks', abbreviation: 'qbo'},
            component: QuickBooksComponent
          },
          {
            path: 'quick-books-connect',
            component: QuickBooksConnectComponent,
          },
          {
            path: 'jobber',
            resolve: { integrations: IntegrationResolverService },
            data: {integration: 'Jobber', abbreviation: 'jobber'},
            component: JobberComponent
          },
          {
            path: 'jobber-connect',
            component: JobberConnectComponent
          }
        ]
      },
      {
        path: 'add-users',
        component: AddUserComponent
      },
      {
        path: 'profile',
        component: ProfileComponent,
        resolve: {userDetails: UserDetailsResolverService }
      },
      { path: 'billing', component: BillingComponent, resolve: {billingDetails: BillingDetailsResolverService} },
      { path: 'update-card-success', component: UpdateCardSuccessComponent, resolve: {updateCardDetails: UpdateCardDetailsResolverService} },
      {
        path: 'cancel-subscription',
        component: CancelSubscriptionComponent,
      },
      { path: 'sales-marketing', component: SalesMarketingComponent },
      { path: 'financials', component: FinancialsComponent },
      { path: 'operations', component: OperationsComponent },
      { path: 'job-costing', component: JobCostingComponent },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: { roles: ['Admin'] },
        children: [{
          path: 'add-client',
          component: AddClientComponent
        }, {
          path: 'all-clients',
          component: AllClientsComponent
        }]
      },
    ],
  },
];
