<div class="login-page">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6 login-section">
        <form [formGroup]="loginForm" (submit)="login()">
          <div class="text-center">
            <img
              class="mb-4 login-logo"
              src="assets/register-popup-logo.png"
              alt=""
              width="64"
              height="64"
            />
          </div>
          <h1 class="h3 mb-3 login-heading">Please sign in</h1>

          <div class="col-12 mb-3">
            <label for="email" class="form-label">Email <span class="text-muted required">*</span></label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="you@example.com"
              formControlName="email"
            />
            @if (loginFormControls?.email?.invalid && (loginFormControls?.email?.dirty || loginFormControls?.email?.touched)) { 
              @if (loginFormControls?.email?.errors?.required) {
              <div class="invalid-feedback">Email is required.</div>
              } 
              @if (loginFormControls?.email?.errors?.email || loginFormControls?.email?.errors?.pattern) {
              <div class="invalid-feedback">Please enter a valid email.</div>
              } 
            }
            @if(loginFormControls?.email?.errors?.serverError) {
              <div class="invalid-feedback">{{loginFormControls?.email?.errors?.serverError}}</div>
            }
          </div>

          <div class="col-12 mb-3">
            <label for="password" class="form-label">Password <span class="text-muted required">*</span></label>
            <show-hide-password class="show-hide-btn" [btnOutline]="false">
              <input
                type="password"
                class="form-control"
                id="password"
                formControlName="password"
              />
            </show-hide-password>
            @if (loginFormControls?.password?.invalid && (loginFormControls?.password?.dirty || loginFormControls?.password?.touched)) { 
              @if (loginFormControls?.password?.errors?.required) {
              <div class="invalid-feedback">Password is required.</div>
              }
            }
            @if(loginFormControls?.password?.errors?.serverError) {
              <div class="invalid-feedback">{{loginFormControls?.password?.errors?.serverError}}</div>
            }
            </div>
          
          <div class="checkbox mb-3">
            <label>
              <input
                type="checkbox"
                value="remember-me"
                formControlName="rememberMe"
              />
              Remember me
            </label>
          </div>
          <button
            class="w-100 btn btn-primary sign-in-button"
            type="submit"
            [disabled]="loginForm.invalid"
          >
            Sign in
          </button>
          
          <p class="mt-2"><a [routerLink]="['/forgot-password']">Forgot your password?</a></p>
        </form>
      </div>
    </div>
  </div>
</div>
