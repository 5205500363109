import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, catchError, filter, takeUntil, throwError } from 'rxjs';
import { UserService } from '@services/user.service';
import { ApiResponse } from '@models/api.response';
import { CountryType } from '@models/country.type';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  profileForm: FormGroup;
  countries: CountryType[];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private toastService: ToastrService
  ) {
    this.countries = [];
    this.profileForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.pattern(environment.NAME_REGEX)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(environment.NAME_REGEX)]),
      phone: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required])
    });
    this.route.data
    .pipe(
      filter((response: any) => !!response?.userDetails?.data),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((data:any) => {
      this.profileForm.patchValue({
        firstName: data?.userDetails?.data?.firstName,
        lastName: data?.userDetails?.data?.lastName,
        phone: data?.userDetails?.data?.phone,
        country: data?.userDetails?.data?.country,
        email: data?.userDetails?.data?.email,
        companyName: data?.userDetails?.data?.companyName
      });
    });
    this.userService.getCountries()
    .pipe(
      filter(response => !!(response?.success && response?.data?.countries)),
      takeUntil(this.componentDestroyed$))
    .subscribe((result: ApiResponse) => {
      this.countries = result.data.countries;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  get profileFormControls(): any {
    return this.profileForm.controls;
  }

  onSubmitProfile(): void {
    if(this.profileForm.valid) {
      const request = {
        firstName: this.profileForm?.controls?.['firstName']?.value.trim(),
        lastName: this.profileForm?.controls?.['lastName']?.value.trim(),
        companyName: this.profileForm?.controls?.['companyName']?.value.trim(),
        phone: this.profileForm?.controls?.['phone']?.value.trim(),
        country: this.profileForm?.controls?.['country']?.value.trim(),
      };
      this.userService.updateProfile(request)
      .pipe(
        filter(response => response?.success),
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.profileForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((result: any) => {
        this.toastService.success(result?.message, result?.title);
      });
    }
  }
}
