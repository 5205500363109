<div class="wrapper">
  <div mode="side" opened [ngClass]="{ thinner: !isExpanded }">
    <app-inner-sidebar [isExpanded]="isExpanded" (toggleSidebar)="onSidebarToggle($event)"></app-inner-sidebar>
  </div>
  <div class="main-content" [ngClass]="{ thinner: !isExpanded }">
    <app-inner-header
      (toggleSidebar)="onSidebarToggle($event)"
    ></app-inner-header>
    <router-outlet></router-outlet>
    <div class="push"></div>
    <app-inner-footer></app-inner-footer>
  </div>
</div>
