import { Component, OnDestroy, OnInit, input } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { CreateCheckoutSessionApiResponse, CreateCheckoutSessionRequest } from '@app/types/checkout.session.api.response.type';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-payment-hosted-page',
  standalone: true,
  imports: [],
  templateUrl: './payment-hosted-page.component.html',
  styleUrl: './payment-hosted-page.component.scss'
})
export class PaymentHostedPageComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  selectedPlan = input.required<any>();
  selectedPaymentType = input.required<string>();
  email = input.required<string>();
  userId = input.required<string>();
  constructor(
    private authService: AuthService
  ) {

  }

  ngOnInit(): void {
    const request: CreateCheckoutSessionRequest = {
      userId: this.userId(),
      email: this.email(),
      priceToken: this.selectedPlan()?.priceToken,
      freeTrial: this.selectedPaymentType() === 'FreeTrial'
    };
    this.authService.createCheckoutSession(request)
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response: CreateCheckoutSessionApiResponse) => {
      window.location.href = response?.data?.checkoutUrl;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}
