<header>
  <div class="container">
    <div
      class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start"
    >
      <a
        href="/"
        class="d-flex align-items-center mt-2 mb-2 text-decoration-none"
      >
        <img src="assets/logo.svg" class="logo img-responsive"/>
      </a>

      <ul
        class="nav col-12 col-lg-auto ms-lg-auto mb-2 justify-content-center mb-md-0"
      >
        <li><a href="#" class="nav-link px-2" [routerLink]="['/']">Home</a></li>
        <li><a href="#" class="nav-link px-2">Features</a></li>
        <li><a href="#" class="nav-link px-2" [routerLink]="['/onboarding']">Pricing</a></li>
        <li><a href="#" class="nav-link px-2">FAQs</a></li>
        <li><a href="#" class="nav-link px-2">About</a></li>
      </ul>

      <div class="text-end">
        <button type="button" class="btn btn-primary me-2" [routerLink]="['/login']">
            <img src="assets/login_white_24dp.svg" alt="login"/> Login
        </button>
        <button type="button" class="btn btn-primary" [routerLink]="['/onboarding']">
            <img src="assets/start_white_24dp.svg" alt="sign up"/> Sign up</button>
      </div>
    </div>
  </div>
</header>
