import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, catchError, filter, takeUntil, throwError } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomValidators } from '@helpers/custom-validators';
import { ShowHideInputDirective, ShowHidePasswordComponent, ShowHideStatusDirective, ShowHideTriggerDirective } from 'ngx-show-hide-password';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-security',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, ShowHidePasswordComponent, ShowHideInputDirective, ShowHideStatusDirective, ShowHideTriggerDirective],
  templateUrl: './user-security.component.html',
  styleUrl: './user-security.component.scss'
})
export class UserSecurityComponent implements OnDestroy{
  private componentDestroyed$: Subject<void> = new Subject();
  passwordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private authService: AuthService
  ) {
    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(/[~@#$%^&*+=_`|{}:;!.?"()[]/ , { hasSpecialCharacters: true })
      ]
    ],
      confirmPassword: ['', Validators.required]
    }, {
      validator: CustomValidators.newPasswordMatchValidator
    });
  }

  get passwordControls(): any {
    return this.passwordForm.controls;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  onSubmitPassword(): void {
    if(this.passwordForm.valid) {
      const request = {
        currentPassword: this.passwordForm?.controls?.['currentPassword']?.value.trim(),
        newPassword: this.passwordForm?.controls?.['newPassword']?.value.trim(),
        confirmNewPassword: this.passwordForm?.controls?.['confirmPassword']?.value.trim(),
      };
      this.authService.changePassword(request)
      .pipe(
        filter(response => response?.success),
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.passwordForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((result: any) => {
        this.toastService.success(result?.message, result?.title);
        this.passwordForm.reset();
      });
    }
  }
}
