import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, input } from '@angular/core';
import { MaterialModule } from '@material/material.module';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { UserService } from '@services/user.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
  selector: 'app-sync-log',
  standalone: true,
  imports: [CommonModule, MaterialModule],
  templateUrl: './sync-log.component.html',
  styleUrl: './sync-log.component.scss'
})
export class SyncLogComponent implements OnInit, OnDestroy, AfterViewInit  {
  private componentDestroyed$: Subject<void> = new Subject();
  code = input.required<string>();
  appLogsDataSource = new MatTableDataSource<any>();
  logsColumns: string[] = ['#', 'Synced With', 'Data Type', 'Status', 'Date'];
  logs: any[] = [];
  totalLogs: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  constructor(
    private userService: UserService,
    private toastService: ToastrService,
    private _liveAnnouncer: LiveAnnouncer
  ) {

  }

  ngOnInit(): void {
    this.refreshTable();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  ngAfterViewInit(): void {
    this.appLogsDataSource.paginator = this.paginator;
    this.appLogsDataSource.sort = this.sort;
  }

  refreshTable(): void {
    const queryParams = {
      sort: '',
      order: 'descending',
      limit: this.pageSize,
      skip: this.pageIndex * this.pageSize,
      code: this.code()
    };
    console.log('🚀 ~ SyncLogComponent ~ refreshTable ~ queryParams:', queryParams)
    this.userService.getSyncLogs(queryParams)
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response: any) => {
      if (response?.success) {
        console.log('🚀 ~ SyncLogComponent ~ .subscribe ~ response:', response?.data?.syncLogs?.logs)
        this.totalLogs = response?.data?.syncLogs?.count;
        this.appLogsDataSource.data = response?.data?.syncLogs?.logs;
     //   this.appLogsDataSource.paginator = this.paginator;
     //   this.appLogsDataSource.sort = this.sort;
      } else {
        this.toastService.error(response?.message, response?.title);
      }
    });
  }

  announceSortChange(sortState: Sort): void {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  onPageChange(_event: any): void {
    this.refreshTable();
  }
}
