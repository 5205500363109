
<div class="integrations">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h4 class="title">Integrations</h4>
                <h6 class="subtitle">Sync all your date from your integrations partners</h6>
            </div>

            <div class="col-md-12">
                <div class="integration-tabs row">
                    <div class="integration-menu col-md-2">
                        <mat-list class="app-toolbox-menu-list">
                            <h3 mat-subheader>GENERAL</h3>
                            <mat-list-item (click)="onChangeTab('All')" [ngClass]="{'active': activeTab === 'All'}">
                                <mat-icon mat-list-icon>apps</mat-icon>
                                <h4 mat-line>All</h4>
                            </mat-list-item>
                            <mat-list-item (click)="onChangeTab('Connected')"
                                [ngClass]="{'active': activeTab === 'Connected'}">
                                <mat-icon mat-list-icon>link</mat-icon>
                                <h4 mat-line>Connected</h4>
                            </mat-list-item>
                            <mat-list-item (click)="onChangeTab('Disconnected')"
                                [ngClass]="{'active': activeTab === 'Disconnected'}">
                                <mat-icon mat-list-icon>link_off</mat-icon>
                                <h4 mat-line>Disconnected</h4>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </mat-list>
                    </div>
                    <div fxFlex class="app-toolbox-body p-4 col-md-10">
                        <div class="toolbox-body-top">
                            <h4 *ngIf="activeTab === 'All'">All Integration Partners</h4>
                            <h4 *ngIf="activeTab === 'Connected'">Connected Integration Partners</h4>
                            <h4 *ngIf="activeTab === 'Disconnected'">Disconnected Integration Partners</h4>
                            <h6 *ngIf="activeTab === 'All'">Connect any integration partner for sync latest data.</h6>
                            <h6 *ngIf="activeTab === 'Connected'">Choose from connected integration partner for sync latest data.</h6>
                            <h6 *ngIf="activeTab === 'Disconnected'">All your disconnected integration partners.</h6>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-4" *ngIf="showQuickBooks">
                                <div class="app-integration-link app-rounded-10 text-center" [routerLink]="['/integrations/quick-books']">
                                    <img class="app-integration-logo" src="assets/images/quickbooks/logo.png" alt="QuickBooks">
                                    <h6 class="status-text text-center text-danger" [class]="{'text-info' : isQBOConnected,'text-danger' : !isQBOConnected }">
                                        {{isQBOConnected ? 'Connected' : 'Disconnected'}}
									</h6>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="showJobber">
                                <div class="app-integration-link app-rounded-10 text-center" [routerLink]="['/integrations/jobber']">
                                    <img class="app-integration-logo" src="assets/images/jobber/logo.png" alt="Jobber">
                                    <h6 class="status-text text-center text-danger" [class]="{'text-info' : isJobberConnected,'text-danger' : !isJobberConnected }">
                                        {{isJobberConnected ? 'Connected' : 'Disconnected'}}
									</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>