<div class="login-page">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6 login-section">
        <app-reset-password
          [email]="email()"
          [token]="token()"
          (updateStep)="updateCurrentStep($event)"
          (updateData)="updateData($event)"
        ></app-reset-password>
      </div>
    </div>
  </div>
</div>
