import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BillingService } from './billing.service';
import { Observable } from 'rxjs';
import { BillingApiResponse } from '@app/types/billing.api.response';

@Injectable({
  providedIn: 'root'
})
export class BillingDetailsResolverService implements Resolve<any> {

  constructor(
    private billingService: BillingService
  ) { }

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<any> | Observable<never> | Observable<any> {
    this.billingService.getBillingDetailsByApi().subscribe((billingApiResponse: BillingApiResponse) => {
      this.billingService.refreshBillingDetails(billingApiResponse?.data);
    });
    return this.billingService.getBillingDetails;
  }
}
