import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { BarGraphComponent } from '@controllers/private/bar-graph/bar-graph.component';
import { DateRangeComponent } from '@controllers/private/date-range/date-range.component';
import { Subject, filter, takeUntil } from 'rxjs';
import { DrillDownService } from '@services/drill-down.service';
import { UserService } from '@services/user.service';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { Dayjs } from 'dayjs/esm';

@Component({
  selector: 'app-financials',
  standalone: true,
  imports: [BarGraphComponent, DateRangeComponent, CommonModule],
  providers: [CurrencyPipe],
  templateUrl: './financials.component.html',
  styleUrl: './financials.component.scss'
})
export class FinancialsComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  netIncomeDollar: number;
  netIncomePercentage: number;
  assets: number;
  liabilities: number;
  statementOfCashFlows: number;
  fetchingInProgress: boolean;
  defaultCurrency: string;
  startAt!: Dayjs;
  endAt!: Dayjs;
  public defaultSelectedGraph = signal<string>('Net Income ($)');
  constructor(
    private drillDownService: DrillDownService,
    private userService: UserService
  ) {
    this.fetchingInProgress = true;
    this.defaultCurrency = 'USD';
    this.netIncomeDollar = 0;
    this.netIncomePercentage = 0;
    this.assets = 0;
    this.liabilities = 0;
    this.statementOfCashFlows = 0;
  }

  ngOnInit(): void {
    this.userService.getSelectedDateRange
    .pipe(
      filter(range => !!range),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((range: TimePeriod) => {
      console.log('🚀 ~ FinancialsComponent ~ .subscribe ~ range:', range)
      this.startAt = range?.startDate;
      this.endAt = range?.endDate;
    });
    this.userService.getFetchState
    .pipe(
      filter(response => response === 'fetched'),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(() => {
      this.updateMatrixData();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  updateMatrixData(): void {
    this.drillDownService.getFinancialData(this.startAt, this.endAt)
    .pipe(
      filter(response => response?.success),
      takeUntil(this.componentDestroyed$)
    ).subscribe((response: any) => {
      this.fetchingInProgress = false;
      this.defaultCurrency = response?.data?.currency;
      this.netIncomeDollar = response?.data?.netIncomeDollar;
      this.netIncomePercentage = response?.data?.netIncomePercentage;
      this.assets = response?.data?.assets;
      this.liabilities = response?.data?.liabilities;
      this.statementOfCashFlows = response?.data?.statementOfCashFlows;
    });
  }

  updateGraph(graphType: string): void {
    this.defaultSelectedGraph.update(() => graphType);
  }
}
