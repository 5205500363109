import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BillingService } from '@app/services/billing.service';
import { UserService } from '@app/services/user.service';
import { BillingApiResponse } from '@app/types/billing.api.response';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject, catchError, filter, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'app-cancel-subscription-reason',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './cancel-subscription-reason.component.html',
  styleUrl: './cancel-subscription-reason.component.scss'
})
export class CancelSubscriptionReasonComponent implements OnDestroy{
  private componentDestroyed$: Subject<void> = new Subject();
  leavingOptions: {name: string, value: string}[];
  companyName: string;
  cancelSubForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<CancelSubscriptionReasonComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private toastService: ToastrService,
    private router: Router,
    private billingService: BillingService
  ) {
    this.companyName = environment.companyName;
    this.leavingOptions = [
      {name: 'The product no longer meets my needs', value: 'unused'},
      {name: 'The product is too expensive', value: 'too_expensive'},
      {name: `Our team never fully adopted ${this.companyName}`, value: 'too_complex'},
      {name: 'I’m switching to a different service', value: 'switched_service'},
      {name: 'Some features are missing', value: 'missing_features'},
      {name: 'Other', value: 'other'}
    ];
    this.cancelSubForm = this.fb.group({
      leavingReason: ['', Validators.required],
      improvement: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  get cancelSubFormControls(): any {
    return this.cancelSubForm.controls;
  }

  cancelSubscription(): void {
    const toastHeading = 'Cancel subscription!';
    if (this.cancelSubForm.valid) {
      this.userService.cancelSubscription({
        leavingReason: this.cancelSubForm.value.leavingReason,
        improvement: this.cancelSubForm.value.improvement
      })
      .pipe(
        filter(res => res?.success),
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.cancelSubForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.refetchBillingDetails();
      });
    } else {
      this.toastService.error('Please fill all required fields', toastHeading);
    }
  }

  closeDialogPopup(): void {
    this.dialogRef.close();
  }

  refetchBillingDetails(): void {
    this.billingService.getBillingDetailsByApi()
    .pipe(
      filter(billingDetails => billingDetails?.success),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((billingDetails: BillingApiResponse) => {
      this.billingService.refreshBillingDetails(billingDetails?.data);
      this.closeDialogPopup();
      this.router.navigate(['/billing']);
    })
  }
}
