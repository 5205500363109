import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  constructor(private router: Router) {}

  goToSalesMarketing(): void {
    this.router.navigate(['/sales-marketing']);
  }

  goToOperations(): void {
    this.router.navigate(['/operations']);
  }

  goToFinancials(): void {
    this.router.navigate(['/financials']);
  }

  goToJobCosting(): void {
    // this.router.navigate(['/job-costing']);
  }
}
