import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { QuickbooksService } from '@services/quickbooks.service';

@Component({
  selector: 'app-quick-books-connect',
  standalone: true,
  imports: [],
  template: ``,
  styles: ``
})
export class QuickBooksConnectComponent  implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastrService,
    private quickBookService: QuickbooksService
  ) {
    const url = window.location.href;
    const queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if(queryParams?.['code'] && queryParams?.['state']) {
      const authData = {
        url: '/callback?' + queryString,
        companyId: queryParams['realmId']
      };
      this.quickBookService.getQBOToken(authData)
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response: any) => {
        if(response?.success) {
          this.toastService.success(response?.message, response?.title);
        } else {
          this.toastService.error(response?.message, response?.title);
        }
        this.router.navigate(['/integrations/quick-books']);
      });
    } else {
      this.toastService.error('Access denied', 'QuickBooks connect!');
      this.router.navigate(['/integrations/quick-books']);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }
}