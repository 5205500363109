import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { inject } from '@angular/core';

export const AuthInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  const toastrService = inject(ToastrService);
  const isLoggedIn = !!authService.getCurrentUserValue()?.accessToken;
  const addAuthHeader = (request: any) => {
    const currentUser = authService.getCurrentUserValue();
    if (currentUser?.accessToken) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${currentUser.accessToken}`
            }
        });
    }
    return request;
  }
  if (isLoggedIn) {
    const cloned = addAuthHeader(req);
    return next(cloned).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            authService.refreshToken()
            .pipe(
              filter(result => result?.success),
              switchMap(() => {
                const request = addAuthHeader(req);
                return next(request);
              }),
              catchError((errorNew: any) => {
                // toastrService.error(errorNew?.message, errorNew?.title);
                return throwError(errorNew);
              })
            )
            .subscribe();
          }
          if(error.status === 403) {
            authService.removeRefreshToken();
          }
          if(error.status === 422) {
            toastrService.error(error?.error?.message, error?.error?.title);
          }
          if(error.status === 500) {
            toastrService.error('something went wrong, please try after some time.', 'Opps something went wrong!');
          }
        }
        return throwError(error);
      })
    );
  } else {
    return next(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if(error.status === 422) {
            toastrService.error(error?.error?.message, error?.error?.title);
          }
          if(error.status === 500) {
            toastrService.error('something went wrong, please try after some time.', 'Opps something went wrong!');
          }
        }
        return throwError(error);
      })
    );
  }

};
