<div class="grid-container">
  <app-date-range></app-date-range>
  <div class="col-md-12">
    <div class="data-grid">
      <div class="row grid-row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          <div>
            @defer (when !fetchingInProgress) {
            <p class="col-head" (click)="updateGraph('Net Income ($)')">
              @if (netIncomeDollar === null) { Incomplete }
              @else {
                {{netIncomeDollar | currency : defaultCurrency}}
              }
            </p>
            <p class="col-data" (click)="updateGraph('Net Income ($)')">Net Income ($)</p>
            } @placeholder {
            <p class="col-head">loading</p>
            <p class="col-data">Net Income ($)</p>
            }
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          <div>
            @defer (when !fetchingInProgress) {
            <p class="col-head" (click)="updateGraph('Net Income (%)')">
              @if (netIncomePercentage === null) { Incomplete }
              @else {
              {{netIncomePercentage | number:'1.2-3'}}%
              }
            </p>
            <p class="col-data" (click)="updateGraph('Net Income (%)')">Net Income (%)</p>
            } @placeholder {
            <p class="col-head">loading</p>
            <p class="col-data">Net Income (%)</p>
            }
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 border-right">
          <div>
            @defer (when !fetchingInProgress) {
            <p class="col-head no-graph">
              {{assets | currency : defaultCurrency}}
            </p>
            } @placeholder {
            <p class="col-head no-graph">loading</p>
            }
            <p class="col-data no-graph">Assets</p>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
          <div>
            @defer (when !fetchingInProgress) {
            <p class="col-head no-graph">
               {{liabilities | currency : defaultCurrency}}
            </p>
            } @placeholder {
            <p class="col-head no-graph">loading</p>
            }
            <p class="col-data no-graph">Liabilities</p>
          </div>
        </div>
      </div>
    </div>
    @defer (when !fetchingInProgress) {
    <app-bar-graph
      [defaultSelectedGraph]="defaultSelectedGraph()"
    ></app-bar-graph>
    } @placeholder {
    <div class="bar-graph">
      <img
        class="img-responsive bar-graph-loader"
        src="assets/bar-graph-placeholder.png"
      />
    </div>
    }
  </div>
</div>
