import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AdminService } from '@services/admin.service';
import { Subject, catchError, filter, takeUntil, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ShowHidePasswordComponent,
  ShowHideInputDirective,
  ShowHideStatusDirective,
  ShowHideTriggerDirective
} from 'ngx-show-hide-password';
import { CustomValidators } from '@helpers/custom-validators';
import { UserService } from '@services/user.service';
import { ApiResponse } from '@models/api.response';
import { CountryType } from '@models/country.type';

@Component({
  selector: 'app-add-client',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ShowHidePasswordComponent, ShowHideInputDirective, ShowHideStatusDirective, ShowHideTriggerDirective],
  templateUrl: './add-client.component.html',
  styleUrl: './add-client.component.scss'
})
export class AddClientComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  createClientForm: FormGroup;
  countries: CountryType[];
  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private userService: UserService,
    private toastService: ToastrService
  ) {
    this.countries = [];
    this.userService.getCountries()
    .pipe(
      filter(response => !!(response?.success && response?.data?.countries)),
      takeUntil(this.componentDestroyed$))
    .subscribe((result: ApiResponse) => {
      this.countries = result.data.countries;
    });
    this.createClientForm = this.formBuilder.group({
      companyName: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required, Validators.pattern(environment.NAME_REGEX)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(environment.NAME_REGEX)]),
      email: new FormControl('', [Validators.required, Validators.pattern(environment.EMAIL_REGEX)]),
      phone: new FormControl('', [Validators.required]),
      country: new FormControl('United States', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(/[~@#$%^&*+=_`|{}:;!.?"()[]/ , { hasSpecialCharacters: true })
      ])
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  get createClientFormControl(): any {
    return this.createClientForm.controls;
  }

  onSubmit(): void {
    if(this.createClientForm?.valid) {
      this.adminService.createClient(this.createClientForm.value)
      .pipe(
        filter(response => response.success),
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.createClientForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response: any) => {
        this.createClientForm.reset();
        this.toastService.success(response?.message, response?.title);
      })

    }
  }

  getClassName(felid: string, _type: string): string {
    if(this.createClientFormControl?.[felid]?.valid &&
      (this.createClientFormControl?.[felid]?.dirty ||
       this.createClientFormControl?.[felid]?.touched)) {
        return 'is-valid';
    }
    if(this.createClientFormControl?.[felid]?.invalid &&
      (this.createClientFormControl?.[felid]?.dirty ||
       this.createClientFormControl?.[felid]?.touched)) {
        return 'is-invalid'
    }
    return ''

  }


}
