<div class="dashboard">
  <div class="dashboard-container container">
    <div class="dashboard-categories">
      <div class="row">
        <div class="category" (click)="goToSalesMarketing()">
          <div class="category-text">
            <p class="category-head">Sales & Marketing</p>
            <p class="see-more">
              See more <img src="assets/wam/arrow.png" alt="" />
            </p>
          </div>
          <img src="assets/wam/marketing.png" />
        </div>
        <div class="category" (click)="goToOperations()">
          <div class="category-text">
            <p class="category-head">Operations</p>
            <p class="see-more">
              See more <img src="assets/wam/arrow.png" alt="" />
            </p>
          </div>
          <img src="assets/wam/opersation.png" />
        </div>
      </div>
      <div class="row">
        <div class="category" (click)="goToFinancials()">
          <div class="category-text">
            <p class="category-head">Financials</p>
            <p class="see-more">
              See more <img src="assets/wam/arrow.png" alt="" />
            </p>
          </div>
          <img src="assets/wam/financial.png" />
        </div>
        <div class="category">
          <div class="category-text">
            <p class="category-head">Job Costing</p>
            <p class="see-more">
              Coming soon
            </p>
          </div>
          <img src="assets/wam/jobcosting.png" />
        </div>
      </div>
    </div>
  </div>
</div>
