import { Component } from '@angular/core';

@Component({
  selector: 'app-jobber-disconnect',
  standalone: true,
  imports: [],
  templateUrl: './jobber-disconnect.component.html',
  styleUrl: './jobber-disconnect.component.scss'
})
export class JobberDisconnectComponent {

}
