import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BillingService } from '@app/services/billing.service';
import { UserService } from '@app/services/user.service';
import { BillingDetails } from '@app/types/billing.api.response';
import { environment } from '@env/environment';
import { filter } from 'rxjs';

@Component({
  selector: 'app-billing',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './billing.component.html',
  styleUrl: './billing.component.scss'
})
export class BillingComponent {
  companyName: string;
  currentPlanName!: string;
  currentPlanTotal!: number;
  currentBillingCycle!: string;
  currentPlanStatus!: string;
  trialStartAt!: string;
  trialEndAt!: string;
  isPaused!: boolean;
  resumeAt!: string;
  constructor(
    private userService: UserService,
    private billingService: BillingService
  ) {
    this.companyName = environment.companyName;
    this.billingService.getBillingDetails
    .subscribe((billingDetails: BillingDetails) => {
      this.currentPlanName = billingDetails?.name;
      this.currentPlanTotal = billingDetails?.amount;
      this.currentBillingCycle = billingDetails?.billingCycle;
      this.currentPlanStatus = billingDetails?.status;
      this.isPaused = billingDetails?.isPaused;
      this.resumeAt = billingDetails?.resumeAt;
      this.trialStartAt = billingDetails?.trialStartAt;
      this.trialEndAt = billingDetails?.trialEndAt;
    });
  }

  updateCard(): void {
    this.userService.updateCreditCard()
    .pipe(
      filter(billingDetails => billingDetails?.success)
    )
    .subscribe((billingDetails: any) => {
      window.open(billingDetails?.data?.sessionUrl, '_self');
    });
  }

  resumeSubscription(): void {

  }
}
