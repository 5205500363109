import { Component, signal } from '@angular/core';
import { MaterialModule } from '@material/material.module';
import { CommonModule, NgIf } from '@angular/common';
import { SyncErrorLogComponent } from '@controllers/private/integrations/sync-error-log/sync-error-log.component';
import { SyncLogComponent } from '@controllers/private/integrations/sync-log/sync-log.component';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
export enum TAB {
  SYNC_NOW = 'Sync Now',
  SYNC_LOGS = 'Sync Logs',
  SYNC_ERROR_LOGS = 'Sync Error Logs'
}
@Component({
  selector: 'app-integration-child',
  standalone: true,
  imports: [MaterialModule, CommonModule, SyncErrorLogComponent, RouterOutlet, SyncLogComponent, NgIf],
  templateUrl: './integration-child.component.html',
  styleUrl: './integration-child.component.scss'
})
export class IntegrationChildComponent {
  integrationParty: string;
  integrationCode = signal<string>('');
  currentTab: TAB;
  syncNow = TAB.SYNC_NOW;
  syncLogs = TAB.SYNC_LOGS;
  syncErrorLogs = TAB.SYNC_ERROR_LOGS;
  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.integrationParty = this.activatedRoute.snapshot.firstChild?.data?.['integration'];
    this.integrationCode.set(this.activatedRoute.snapshot.firstChild?.data?.['abbreviation']);
    this.currentTab = TAB.SYNC_NOW;
  }

  onTabChange(tab: TAB): void {
    this.currentTab = tab;
  }
}
