<div class="profile">
  <div class="profile-container container">
  <div class="col-12 mb-3">
      <div class="row">
        <div class="col-12">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
            <mat-tab label="Profile">
              <ng-template matTabContent class="tab-content">
                <div class="row">
                  <div class="col-12 col-md-auto mb-3">
                    <div class="card-avatar">
                      <button mat-icon-button (click)="userAvatarLogoFile.click()" class="mt-4 text-center app-profile-btn">
                        <img src="assets/images/camera.png" class="w-100" />
                        <input class="ng-hide" #userAvatarLogoFile id="input-file-id" type="file" (change)="onUserAvatarFileChange($event)" style="display: none"/>
                      </button>
                      <img [src]="userAvatar ? userAvatar : 'assets/images/images.png'" class="w-100"/>
                    </div>
                  </div>
                </div>
                <app-user-profile></app-user-profile>
              </ng-template>
            </mat-tab>
            <mat-tab label="Security">
              <ng-template matTabContent>
                <app-user-security></app-user-security>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>