<div class="grid-container">
  <app-date-range></app-date-range>
  <div class="col-md-12">
    <div class="data-grid">
      <div class="row grid-row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-center border-right">
          @defer (when !fetchingInProgress) {
              <p class="col-head" (click)="updateGraph('Cash Collected')">
                {{ cashCollected | currency }}
              </p>
              <p class="col-data" (click)="updateGraph('Cash Collected')">Cash Collected</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Cash Collected</p>
          }
        </div>
        <div
          class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-center border-right"
        >
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('New Invoices Created')">{{newInvoicesCreated | currency }}</p>
          <p class="col-data" (click)="updateGraph('New Invoices Created')">New Invoices Created</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">New Invoices Created</p>
          }
        </div>
        <div
          class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-center border-right"
        >
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Leads')">{{leads}}</p>
          <p class="col-data" (click)="updateGraph('Leads')">Leads</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Leads</p>
          }
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-center">
          @defer (when !fetchingInProgress) {
            @if (costPerLead === null) {
              <p class="col-head" (click)="updateGraph('Cost Per Lead')">Incomplete</p>
            }
            @else {
              <p class="col-head" (click)="updateGraph('Cost Per Lead')">{{costPerLead | currency}}</p>
            }
          <p class="col-data" (click)="updateGraph('Cost Per Lead')">Cost Per Lead</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Cost Per Lead</p>
          }
        </div>
      </div>
      <div class="row grid-row">
        <div
          class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-center border-right"
        >
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('New Customers')">{{newCustomers}}</p>
          <p class="col-data" (click)="updateGraph('New Customers')">New Customers</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">New Customers</p>
          }
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-center border-right">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Customer Acquisition Cost')">
            @if (customerAcquisitionCost === null) { Incomplete }
            @else {
              {{customerAcquisitionCost | currency}} 
            }
          </p>
          <p class="col-data" (click)="updateGraph('Customer Acquisition Cost')">Customer Acquisition Cost</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Customer Acquisition Cost</p>
          }
        </div>
        <div class="col-xs-6 col-lg-6 text-center">
          @defer (when !fetchingInProgress) {
          <p class="col-head" (click)="updateGraph('Repeat Customers')">{{repeatCustomers}}</p>
          <p class="col-data" (click)="updateGraph('Repeat Customers')">Repeat Customers</p>
          } @placeholder {
          <p class="col-head">loading</p>
          <p class="col-data">Repeat Customers</p>
          }
        </div>
      </div>
    </div>
  </div>
  @defer (when !fetchingInProgress) {
  <app-bar-graph
    [defaultSelectedGraph]="defaultSelectedGraph()"
  ></app-bar-graph>
  } @placeholder {
  <div class="bar-graph">
    <img
      class="img-responsive bar-graph-loader"
      src="assets/bar-graph-placeholder.png"
    />
  </div>
  }
</div>
