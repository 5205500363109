import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuickbooksService {
  api: string;
  constructor(
    private router: Router,
    private httpClient: HttpClient,
  ) {
    this.api = environment.API_URL;
  }

  connectQBO(): Observable<any> {
    return this.httpClient.post<any>(this.api + 'connect-qbo', { });
  }

  disconnectQBO(): Observable<any> {
    return this.httpClient.post<any>(this.api + 'disconnect-qbo', { });
  }

  getQBOToken(requestData: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-qbo-token', requestData);

  }

  syncQBOReports(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'sync-qbo-reports');
  }

  syncQBOAccounts(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'sync-qbo-accounts');
  }

  syncQBOAmountSpentOnMarketing(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'sync-qbo-amount-spent-on-marketing');
  }

  stopSync(request: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'stop-qbo-sync', request);
  }

  getErrorLogs(request: any): Observable<any> {
    return this.httpClient.post<any>(this.api + 'get-error-logs', request);
  }

  getAccountsList(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'get-qbo-accounts');
  }

  getQuickBooksDetails(): Observable<any> {
    return this.httpClient.get<any>(this.api + 'get-qbo-details');
  }

  disconnectQboCompany(realmId: string): Observable<any> {
    return this.httpClient.post<any>(this.api + 'disconnect-qbo-company', {realmId});
  }
}
