import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { InnerFooterComponent } from '@controllers/private/inner-footer/inner-footer.component';
import { InnerHeaderComponent } from '@controllers/private/inner-header/inner-header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { InnerSidebarComponent } from '@controllers/private/inner-sidebar/inner-sidebar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-private-base',
  standalone: true,
  imports: [
    RouterOutlet,
    InnerHeaderComponent,
    InnerSidebarComponent,
    InnerFooterComponent,
    MatSidenavModule,
    CommonModule,
  ],
  templateUrl: './private-base.component.html',
  styleUrls: ['./private-base.component.scss'],
})
export class PrivateBaseComponent {
  isExpanded: boolean = false;

  onSidebarToggle(isExpanded: boolean): void {
    this.isExpanded = isExpanded;
    console.log('from navbar', isExpanded);
  }
}
