import { CommonModule } from '@angular/common';
import { Component, OnDestroy, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { REGISTER_STEP } from '@app/models/register.step.enum';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { PaymentHostedPageComponent } from './payment-hosted-page/payment-hosted-page.component';
import { NewUserDetailsComponent } from './new-user-details/new-user-details.component';
import { NewUserData } from '@app/types/new.user.details.api.response';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [CommonModule, NewUserDetailsComponent, VerifyEmailComponent, PaymentHostedPageComponent],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})
export class PricingComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  plans = signal<any[]>([]);
  selectedPlan = signal<any>({});
  selectedPaymentType = signal<string>('');
  userId = signal<string>('');
  token = signal<string>('');
  email = signal<string>('');
  currentStep: REGISTER_STEP;
  constructor(
    private route: ActivatedRoute,
  ) {
    this.currentStep = REGISTER_STEP.SELECT_PLAN;
    this.route.data.pipe(
      filter((response: any) => !!(response?.pricingPlansList?.success && response?.pricingPlansList?.data)),
      takeUntil(this.componentDestroyed$)
    ).subscribe((response: any) => {
      this.plans.update(() => response?.pricingPlansList?.data?.plans);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  yearlyPlan(plans: any[]): any[] {
    return plans.filter(p => p.billingCycle === 'Yearly');
  }

  monthlyPlan(plans: any[]): any[] {
    return plans.filter(p => p.billingCycle === 'Monthly');
  }

  selectPlan(plan: any, type: string): void {
    this.selectedPlan.update(() => plan);
    this.selectedPaymentType.update(()=> type);
    this.currentStep = REGISTER_STEP.ENTER_PERSONAL_DETAILS;
  }

  updateCurrentStep(step: REGISTER_STEP): void {
    this.currentStep = step;
  }

  updateData(data: NewUserData): void {
    this.userId.update(()=> data.userId);
    this.token.update(()=> data.token);
    this.email.update(()=> data.email);
  }
}
