<div class="toolbox-body-top">
  <h4 class="m-0">{{integration}}</h4>
  @if (!lastSuccessSync) {
    <h6>Seems like you never sync with {{integration}}, Try sync now! </h6>
  }
  @else {
    <h6>Last Synced: {{ lastSuccessSync?.syncedDetails?.endedAt | date: 'MMM d, y, h:mm:ss a' }}</h6>
  }
</div>
<hr/>
<div class="row">
  <div class="app-sync-list-wrapper col-md-6">
    <ul class="app-sync-list app-qbo">
      <li class="app-sync-li app-card app-card-solid app-shadow-low">
        <div class="app-li-title">
          <strong>Expense accounts</strong>
        </div>
        @if (isAccountsLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <div class="app-li-description mb-2">
          @if (!latestSuccessSyncLogsData?.accounts) {
            <small>Expense accounts never synced</small>
          }
          @else {
            <small>Last Synced: {{ latestSuccessSyncLogsData?.accounts?.syncedDetails?.endedAt | date : "MMM d, y, h:mm:ss a" }}</small>
          }
        </div>
        <button mat-raised-button (click)="syncQBOAccounts()" [disabled]="isAccountsLoading || !isQBOConnected">
          Sync Now
        </button>
        @if (isAccountsLoading) {
          <button mat-icon-button (click)="onCancel('accounts')" class="float-right" color="warn" matTooltip="Force Stop">
            <mat-icon>block</mat-icon>
          </button>
        }
        <br>
        <button class="mt-1" mat-raised-button (click)="openExpensesAccountPopup()" [disabled]="!isQBOConnected || isAccountsLoading || !latestSuccessSyncLogsData?.accounts">
          Change marketing accounts
        </button>
      </li>
      <!-- <li class="app-sync-li app-card app-card-solid app-shadow-low">
        <div class="app-li-title">
          <strong>Amount spent on marketing</strong>
        </div>
        @if (isAmountSpentOnMarketingLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <div class="app-li-description mb-2">
          @if (!latestSuccessSyncLogsData?.amountSpentOnMarketing) {
            <small>Amount spent on marketing never synced</small>
          }
          @else {
            <small>Last Synced: {{ latestSuccessSyncLogsData?.amountSpentOnMarketing?.syncedDetails?.endedAt | date : "MMM d, y, h:mm:ss a"}}</small>
          }
        </div>
        <button mat-raised-button (click)="syncQBOAmountSpentOnMarketing()" [disabled]="!isQBOConnected || isAmountSpentOnMarketingLoading">
          Sync Now
        </button>
        @if (isAmountSpentOnMarketingLoading) {
          <button mat-icon-button (click)="onCancel('AMOUNT_SPEND_ON_MARKETING')" class="float-right" color="warn" matTooltip="Force Stop">
            <mat-icon>block</mat-icon>
          </button>
        }
      </li>
      <li class="app-sync-li app-card app-card-solid app-shadow-low">
        <div class="app-li-title">
          <strong>Reports</strong>
        </div>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="isReportsLoading"
        ></mat-progress-bar>
        <div class="app-li-description mb-2">
          @if (!latestSuccessSyncLogsData?.reports) {
            <small>Reports never synced</small>
          }
          @else {
            <small>Last Synced: {{ latestSuccessSyncLogsData?.reports?.syncedDetails?.endedAt }}</small>
          }
        </div>
        <button mat-raised-button (click)="syncQBOReports()" [disabled]="!isQBOConnected || isReportsLoading">
          Sync Now
        </button>
        @if (isReportsLoading) {
          <button mat-icon-button (click)="onCancel('REPORTS')" class="float-right" color="warn" matTooltip="Force Stop">
            <mat-icon>block</mat-icon>
          </button>
        }
      </li> -->
    </ul>
  </div>
  <div class="app-connect-wrapper col-md-6">
    @if (isQBOConnected) {
      <p><b>Company Name:</b> {{qboCompanyName}}</p>
      <p><b>Company ID:</b> {{qboCompanyId}}</p>
      <button mat-raised-button color="primary" (click)="disconnectQBO()" class="app-img-btn app-btn-quickbook-disconnect" [disabled]="isReportsLoading || isAccountsLoading || isAmountSpentOnMarketingLoading">
        Disconnect QuickBooks
      </button>
    }
    @else {
      <button mat-raised-button color="primary" (click)="connectQBO()" class="app-img-btn app-btn-quickbook-connect">
        Connect QuickBooks
      </button>
    }
  </div>
</div>