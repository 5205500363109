import { Component } from '@angular/core';
import { environment } from '@env/environment';
import {RouterModule} from '@angular/router';

@Component({
  selector: 'app-inner-footer',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './inner-footer.component.html',
  styleUrl: './inner-footer.component.scss'
})

export class InnerFooterComponent {
  copyrightTerms: string = `©  ${new Date().getFullYear()} ${environment.companyName}`;
}
