import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { BarGraphComponent } from '@controllers/private/bar-graph/bar-graph.component';
import { DateRangeComponent } from '@controllers/private/date-range/date-range.component';
import { Subject, filter, takeUntil } from 'rxjs';
import { UserService } from '@services/user.service';
import { DrillDownService } from '@services/drill-down.service';
import { Dayjs } from 'dayjs/esm';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { CommonModule, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-operations',
  standalone: true,
  imports: [BarGraphComponent, DateRangeComponent, CommonModule],
  providers: [CurrencyPipe],
  templateUrl: './operations.component.html',
  styleUrl: './operations.component.scss'
})
export class OperationsComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  fetchingInProgress: boolean;
  public defaultSelectedGraph = signal<string>('Gross Profit Margin');
  defaultCurrency: string;
  grossProfitMargin: number;
  revenuePerLaborHour: number;
  averageVisitRevenue: number;
  averageProfitPerVisit: number;
  visitsCompleted: number;
  averageVisitsPerCustomer: number;
  averageCustomerLifetimeProfit: number;
  startAt!: Dayjs;
  endAt!: Dayjs;
  constructor(
    private userService: UserService,
    private drillDownService: DrillDownService
  ) {
    this.fetchingInProgress = true;
    this.defaultCurrency = 'USD';
    this.grossProfitMargin = 0;
    this.revenuePerLaborHour = 0;
    this.averageVisitRevenue = 0;
    this.averageProfitPerVisit = 0;
    this.visitsCompleted = 0;
    this.averageVisitsPerCustomer = 0;
    this.averageCustomerLifetimeProfit = 0;
  }

  ngOnInit(): void {
    this.userService.getSelectedDateRange
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((range: TimePeriod) => {
      this.startAt = range?.startDate;
      this.endAt = range?.endDate;
    });
    this.userService.getFetchState
    .pipe(
      filter(response => response === 'fetched'),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(() => {
      this.updateMatrixData();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  updateMatrixData(): void {
    this.drillDownService.getOperationData(this.startAt, this.endAt)
    .pipe(
      filter(response => response?.success),
      takeUntil(this.componentDestroyed$)
    ).subscribe((response: any) => {
      this.fetchingInProgress = false;
      this.defaultCurrency = response?.data?.currency;
      this.grossProfitMargin = response?.data?.grossProfitMargin;
      this.revenuePerLaborHour = response?.data?.revenuePerLaborHour;
      this.averageVisitRevenue = response?.data?.averageVisitRevenue;
      this.averageProfitPerVisit = response?.data?.averageProfitPerVisit;
      this.visitsCompleted = response?.data?.visitsCompleted;
      this.averageVisitsPerCustomer = response?.data?.averageVisitsPerCustomer;
      this.averageCustomerLifetimeProfit = response?.data?.averageCustomerLifetimeProfit;
    })
  }

  updateGraph(graphType: string): void {
    this.defaultSelectedGraph.update(() => graphType);
  }
}
