import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule  } from '@angular/material/select';
import { Subject, filter, takeUntil } from 'rxjs';
import { QuickbooksService } from '@services/quickbooks.service';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-expenses-account-popup',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './expenses-account-popup.component.html',
  styleUrl: './expenses-account-popup.component.scss'
})
export class ExpensesAccountPopupComponent {
  private componentDestroyed$: Subject<void> = new Subject();

  selectedAccounts = new FormControl();
  accountsList: any[];
  constructor(
    private qboService: QuickbooksService,
    private userService: UserService,
    private toastrService: ToastrService
  ) {
    this.accountsList = [];
    this.getAllAccounts();
    this.getSavedMarketingAccounts();
  }

  getSavedMarketingAccounts(): void {
    this.userService.getAccounts().pipe(
      filter(res => res?.success),
      takeUntil(this.componentDestroyed$)
    ).subscribe((res: any) => {
      this.selectedAccounts.patchValue(res?.data?.marketingAccounts);
    });
  }

  getAllAccounts(): void {
    this.qboService.getAccountsList().pipe(
      filter(res => res?.success),
      takeUntil(this.componentDestroyed$)
    ).subscribe((res: any) => {
      this.accountsList = res?.data?.accounts;
    });
  }

  saveAccount(): void {
    if (!this.selectedAccounts.value) {
      this.toastrService.error('Please select an account.', 'Sales/Marketing account');
    }
    this.userService.saveAccounts(this.selectedAccounts.value).pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((res: any) => {
      if(res?.success) {
        this.toastrService.success(res?.message, res?.title);
      } else {
        this.toastrService.error(res?.message, res?.title);
      }
    });
  }




}
