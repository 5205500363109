import { Component } from '@angular/core';

@Component({
  selector: 'app-sync-error-log',
  standalone: true,
  imports: [],
  templateUrl: './sync-error-log.component.html',
  styleUrl: './sync-error-log.component.scss'
})
export class SyncErrorLogComponent {

}
