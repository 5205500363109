<h2 mat-dialog-title>Select Sales/Marketing accounts</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field class="accounts-list">
    <mat-label>Accounts</mat-label>
    <mat-select [formControl]="selectedAccounts" multiple>
      <mat-select-trigger>
        {{selectedAccounts.value?.[0]?.Name }}
        @if ((selectedAccounts.value?.length || 0) > 1) {
        <span class="example-additional-selection">
          (+{{ (selectedAccounts.value?.length || 0) - 1 }}
          {{ selectedAccounts.value?.length === 2 ? "other" : "others" }})
        </span>
        }
      </mat-select-trigger>
      @for (account of accountsList; track account?.Id) {
      <mat-option [value]="account">{{ account?.Name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div>
  Saved Marketing accounts
  <ol>
  @for (account of selectedAccounts.value; track $index) {
    <li>{{account?.Name}}</li>
  }
</ol>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    class="btn-primary"
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="saveAccount()"
  >
    Save
  </button>
</mat-dialog-actions>
