import { Component } from '@angular/core';

@Component({
  selector: 'app-all-clients',
  standalone: true,
  imports: [],
  templateUrl: './all-clients.component.html',
  styleUrl: './all-clients.component.scss'
})
export class AllClientsComponent {

}
