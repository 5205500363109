<div class="col-md-12 mt-4">
    <form class="form" [formGroup]="profileForm" (submit)="onSubmitProfile()">
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col mt-3">
                        <label for="firstName" class="form-label">First name <span class="text-muted required">*</span></label>
                        <input class="form-control" type="text" name="firstName" id="firstName" placeholder="John Smith" formControlName="firstName" required/>
                        @if (profileFormControls?.firstName?.invalid && (profileFormControls?.firstName?.dirty || profileFormControls?.firstName?.touched)) { 
                            @if (profileFormControls?.firstName?.errors?.required) {
                            <div class="invalid-feedback">Company name is required.</div>
                            }
                        }
                        @if(profileFormControls?.firstName?.errors?.serverError) {
                        <div class="invalid-feedback">{{profileFormControls?.firstName?.errors?.serverError}}</div>
                        }
                    </div>
                    <div class="col mt-3">
                        <label for="lastName" class="form-label">Last name <span class="text-muted required">*</span></label>
                        <input class="form-control" type="text" name="lastName" placeholder="John Smith"formControlName="lastName"/>
                        @if (profileFormControls?.lastName?.invalid && (profileFormControls?.lastName?.dirty || profileFormControls?.lastName?.touched)) { 
                            @if (profileFormControls?.lastName?.errors?.required) {
                            <div class="invalid-feedback">Last name is required.</div>
                            }
                        }
                        @if(profileFormControls?.lastName?.errors?.serverError) {
                        <div class="invalid-feedback">{{profileFormControls?.lastName?.errors?.serverError}}</div>
                        }
                    </div>
                    <div class="col mt-3">
                        <label for="phone" class="form-label">Phone <span class="text-muted required">*</span></label>
                        <input class="form-control" type="text" name="username" formControlName="phone"/>
                        @if (profileFormControls?.phone?.invalid && (profileFormControls?.phone?.dirty || profileFormControls?.phone?.touched)) { 
                            @if (profileFormControls?.phone?.errors?.required) {
                            <div class="invalid-feedback">Phone is required.</div>
                            }
                        }
                        @if(profileFormControls?.phone?.errors?.serverError) {
                        <div class="invalid-feedback">{{profileFormControls?.phone?.errors?.serverError}}</div>
                        }
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-3">
                        <label for="email" class="form-label">Email <span class="text-muted required">*</span></label>
                        <input class="form-control" type="email" placeholder="user@example.com" formControlName="email" readonly/>
                    </div>
                    <div class="col mt-3">
                        <label for="companyName" class="form-label">Company name <span class="text-muted required">*</span></label>
                        <input class="form-control" type="text" formControlName="companyName">
                        @if (profileFormControls?.companyName?.invalid && (profileFormControls?.companyName?.dirty || profileFormControls?.companyName?.touched)) { 
                            @if (profileFormControls?.companyName?.errors?.required) {
                            <div class="invalid-feedback">Phone is required.</div>
                            }
                        }
                        @if(profileFormControls?.companyName?.errors?.serverError) {
                        <div class="invalid-feedback">{{profileFormControls?.companyName?.errors?.serverError}}</div>
                        }
                    </div>
                    <div class="col mt-3">
                        <label for="country" class="form-label">Country <span class="text-muted required">*</span></label>
                        <select class="form-control" formControlName="country">
                            <option>Select Country</option>
                            @for (country of countries; track $index) {
                            <option [value]="country?.countryName">{{ country?.countryName }}</option>
                            }
                        </select>
                        @if (profileFormControls?.country?.invalid && (profileFormControls?.country?.dirty || profileFormControls?.country?.touched)) { 
                            @if (profileFormControls?.country?.errors?.required) {
                            <div class="invalid-feedback">Phone is required.</div>
                            }
                        }
                        @if(profileFormControls?.country?.errors?.serverError) {
                        <div class="invalid-feedback">{{profileFormControls?.country?.errors?.serverError}}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col d-flex justify-content-end mt-2">
                <button class="btn btn-primary" type="submit" [disabled]="profileForm.invalid">Save Change</button>
            </div>
        </div>
    </form>
</div>

