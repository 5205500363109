import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { UserService } from '@app/services/user.service';
import { BillingApiResponse, BillingDetails } from '@app/types/billing.api.response';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { Subject, filter, takeUntil } from 'rxjs';
import { CancelSubscriptionReasonComponent } from '../cancel-subscription-reason/cancel-subscription-reason.component';
import { CommonModule } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BillingService } from '@app/services/billing.service';

@Component({
  selector: 'app-cancel-subscription',
  standalone: true,
  imports: [CommonModule, RouterModule, MatDialogModule, CancelSubscriptionReasonComponent, SweetAlert2Module],
  templateUrl: './cancel-subscription.component.html',
  styleUrl: './cancel-subscription.component.scss'
})
export class CancelSubscriptionComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  companyName: string;
  currentPlanStatus!: string;
  isPaused!: boolean;
  resumeAt!: string;
  supportEmail: string;
  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private toastService: ToastrService,
    private router: Router,
    private billingService: BillingService
  ) {
    this.companyName = environment.companyName;
    this.supportEmail = environment.SUPPORT_EMAIL;
    this.billingService.getBillingDetails
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((billingDetails: BillingDetails) => {
      this.currentPlanStatus = billingDetails?.status;
      if (this.currentPlanStatus === 'canceled') {
        this.toastService.error('You can`t access the cancel subscription route due to your plan status. Please connect with our support team.', 'Cancel subscription!');
        this.router.navigate(['/billing']);
      }
      this.isPaused = billingDetails?.isPaused;
      this.resumeAt = billingDetails?.resumeAt;
    });

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  openCancelSubscriptionPopup(): void {
    const dialogRef = this.dialog.open(CancelSubscriptionReasonComponent);
    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  pauseSubscription(): void {
    const toastTitle = 'Pause subscription!';
    this.userService.pauseSubscription()
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((result: any) => {
      if (result?.success) {
        this.toastService.success(result?.message, 'Success!');
        this.refetchBillingDetails();
      } else {
        this.toastService.error(result?.message, toastTitle);
      }
    });
  }

  resumeSubscription(): void {
    const toastTitle = 'Resume subscription!';
    this.userService.resumeSubscription()
    .pipe(
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((result: any) => {
      if (result?.success) {
        this.toastService.success(result?.message, toastTitle);
        this.refetchBillingDetails();
      } else {
        this.toastService.error(result?.message, toastTitle);
      }
    });
  }

  refetchBillingDetails(): void {
    this.billingService.getBillingDetailsByApi()
    .pipe(
      filter(billingDetails => billingDetails?.success),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((billingDetails: BillingApiResponse) => {
      this.billingService.refreshBillingDetails(billingDetails?.data);
      this.router.navigate(['/billing']);
    })
  }
}
