import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { BarGraphComponent } from '@controllers/private/bar-graph/bar-graph.component';
import { DrillDownService } from '@services/drill-down.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { DateRangeComponent } from '@controllers/private/date-range/date-range.component';
import { UserService } from '@services/user.service';
import { CommonModule } from '@angular/common';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';
import { Dayjs } from 'dayjs/esm';

@Component({
  selector: 'app-sales-marketing',
  standalone: true,
  imports: [BarGraphComponent, DateRangeComponent, CommonModule],
  templateUrl: './sales-marketing.component.html',
  styleUrl: './sales-marketing.component.scss'
})
export class SalesMarketingComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  cashCollected: number;
  newInvoicesCreated: number;
  leads: number;
  costPerLead: number;
  newCustomers: number;
  customerAcquisitionCost: number;
  repeatCustomers: number;
  fetchingInProgress: boolean;
  startAt!: Dayjs;
  endAt!: Dayjs;
  public defaultSelectedGraph = signal<string>('Cash Collected');
  constructor(
    private drillDownService: DrillDownService,
    private userService: UserService
  ) {
    this.fetchingInProgress = true;
    this.cashCollected = 0.00;
    this.newInvoicesCreated = 0;
    this.leads = 0;
    this.costPerLead = 0.00;
    this.newCustomers = 0;
    this.customerAcquisitionCost = 0.00;
    this.repeatCustomers = 0;
  }

  ngOnInit(): void {
    this.userService.getSelectedDateRange
    .pipe(
      filter(range => !!range),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((range: TimePeriod) => {
      console.log('🚀 ~ SalesMarketingComponent ~ .subscribe ~ range:', range)
      this.startAt = range?.startDate;
      this.endAt = range?.endDate;
    });
    this.userService.getFetchState
    .pipe(
      filter(response => response === 'fetched'),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(() => {
      this.updateMatrixData();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  updateMatrixData(): void {
    this.drillDownService.getSalesMarketingData(this.startAt, this.endAt)
    .pipe(
      filter(response => response?.success),
      takeUntil(this.componentDestroyed$)
    ).subscribe((response: any) => {
      this.fetchingInProgress = false;
      this.cashCollected = response?.data?.cashCollected;
      this.newInvoicesCreated = response?.data?.newInvoicesCreated;
      this.leads = response?.data?.leads;
      this.costPerLead = response?.data?.costPerLead;
      this.newCustomers = response?.data?.newCustomers;
      this.customerAcquisitionCost = response?.data?.customerAcquisitionCost;
      this.repeatCustomers = response?.data?.repeatCustomers;
    })
  }

  updateGraph(graphType: string): void {
    this.defaultSelectedGraph.update(() => graphType);
  }

}
