import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { RouterModule} from '@angular/router';
import { environment } from '@env/environment';
import { ShowHidePasswordComponent, ShowHideInputDirective, ShowHideStatusDirective, ShowHideTriggerDirective } from 'ngx-show-hide-password';
import { Subject, catchError, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, ShowHidePasswordComponent, ShowHideInputDirective, ShowHideStatusDirective, ShowHideTriggerDirective],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  providers: [HttpClientModule],
})
export class LoginComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  loginForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(environment.EMAIL_REGEX)]),
      password: new FormControl('', [Validators.required]),
      rememberMe: new FormControl(''),
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  get loginFormControls(): any {
    return this.loginForm.controls;
  }

  login(): void {
    if(this.loginForm.valid) {
      const email = this.loginForm?.controls?.['email']?.value?.toLowerCase().trim();
      const password = this.loginForm?.controls?.['password']?.value?.trim();
      this.authService.login(email, password)
      .pipe(
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.loginForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((result) => {
        if(result?.success) {
          this.toastrService.success(result?.message, result?.title);
        } else {
          this.toastrService.error(result?.message, result?.title);
        }
      });
    }
  }
}
