<div class="register-page">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6 register-section">
        <div class="text-center">
          <img
            class="mb-4 register-logo"
            src="assets/register-popup-logo.png"
            alt=""
            width="64"
            height="64"
          />
        </div>
        <h1 class="h3 mb-3 login-heading">Redirecting to payment page ...</h1>
      </div>
    </div>
  </div>
</div>
