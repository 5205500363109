import { Component } from '@angular/core';

@Component({
  selector: 'app-quick-books-disconnect',
  standalone: true,
  imports: [],
  templateUrl: './quick-books-disconnect.component.html',
  styleUrl: './quick-books-disconnect.component.scss'
})
export class QuickBooksDisconnectComponent {

}
