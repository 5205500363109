<div class="register-page">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-6 register-section">
        <form [formGroup]="createClientForm" (submit)="onSubmit()" autocomplete="off">
          <div class="text-center">
            <img
              class="mb-4 register-logo"
              src="assets/register-popup-logo.png"
              alt=""
              width="64"
              height="64"
            />
          </div>
          <h1 class="register-heading text-center">Register a new client</h1>

          <div class="col-12 mb-3">
            <label for="buinessName" class="form-label"
              >Company Name <span class="text-muted required">*</span></label
            >
            <input
              type="companyName"
              class="form-control"
              id="companyName"
              formControlName="companyName"
              placeholder=""
              [ngClass]="getClassName('companyName', 'inputClass')"
            />
            @if (createClientFormControl?.companyName?.invalid && (createClientFormControl?.companyName?.dirty || createClientFormControl?.companyName?.touched)) { 
                @if (createClientFormControl?.companyName?.errors?.required) {
                <div class="invalid-feedback">Company name is required.</div>
                }
            }
            @if(createClientFormControl?.companyName?.errors?.serverError) {
              <div class="invalid-feedback">{{createClientFormControl?.companyName?.errors?.serverError}}</div>
            }
          </div>
          <div class="row">
            <div class="col-6 mb-3">
              <label for="firstName" class="form-label"
                >First name <span class="text-muted required">*</span></label
              >
              <input
                type="firstName"
                class="form-control"
                id="firstName"
                formControlName="firstName"
                [ngClass]="getClassName('firstName', 'inputClass')"
            
              />
            @if (createClientFormControl?.firstName?.invalid && (createClientFormControl?.firstName?.dirty || createClientFormControl?.firstName?.touched)) { 
                @if (createClientFormControl?.firstName?.errors?.required) {
                <div class="invalid-feedback">First name is required.</div>
                } 
                @if (createClientFormControl?.firstName?.errors?.pattern) {
                <div class="invalid-feedback">Please enter a valid first name.</div>
                } 
            }
            @if(createClientFormControl?.firstName?.errors?.serverError) {
              <div class="invalid-feedback">{{createClientFormControl?.firstName?.errors?.serverError}}</div>
            }
            </div>

            <div class="col-6 mb-3">
              <label for="firstName" class="form-label"
                >Last name <span class="text-muted required">*</span></label
              >
              <input
                type="lastName"
                class="form-control"
                id="lastName"
                formControlName="lastName"
                [ngClass]="getClassName('lastName', 'inputClass')"
              />
              @if (createClientFormControl?.lastName?.invalid && (createClientFormControl?.lastName?.dirty || createClientFormControl?.lastName?.touched)) { 
                @if (createClientFormControl?.lastName?.errors?.required) {
                <div class="invalid-feedback">Last name is required.</div>
                } 
                @if (createClientFormControl?.lastName?.errors?.pattern) {
                <div class="invalid-feedback">Please enter a valid last name.</div>
                } 
            }
            @if(createClientFormControl?.lastName?.errors?.serverError) {
              <div class="invalid-feedback">{{createClientFormControl?.lastName?.errors?.serverError}}</div>
            }
            </div>
          </div>

          <div class="row">
            <div class="col-6 mb-3">
              <label for="phone" class="form-label"
                >Phone <span class="text-muted required">*</span></label
              >
              <input
                type="text"
                class="form-control"
                formControlName="phone"
                [ngClass]="getClassName('phone', 'inputClass')"
              />
              @if (createClientFormControl?.phone?.invalid && (createClientFormControl?.phone?.dirty || createClientFormControl?.phone?.touched)) { 
                @if (createClientFormControl?.phone?.errors?.required) {
                <div class="invalid-feedback">Phone is required.</div>
                }
            }
            @if(createClientFormControl?.phone?.errors?.serverError) {
              <div class="invalid-feedback">{{createClientFormControl?.phone?.errors?.serverError}}</div>
            }
            </div>
            <div class="col-6 mb-3">
              <label for="country" class="form-label">Country <span class="text-muted required">*</span></label>
              <select class="form-control" formControlName="country">
                  <option>Select Country</option>
                  @for (country of countries; track $index) {
                  <option [value]="country?.countryName">{{ country?.countryName }}</option>
                  }
              </select>
              @if (createClientFormControl?.country?.invalid && (createClientFormControl?.country?.dirty || createClientFormControl?.country?.touched)) { 
                  @if (createClientFormControl?.country?.errors?.required) {
                  <div class="invalid-feedback">Phone is required.</div>
                  }
              }
              @if(createClientFormControl?.country?.errors?.serverError) {
              <div class="invalid-feedback">{{createClientFormControl?.country?.errors?.serverError}}</div>
              }
          </div>
          </div>
          <div class="row">
            <div class="col-6 mb-3">
              <label for="firstName" class="form-label"
                >Email <span class="text-muted required">*</span></label
              >
              <input
                type="firstName"
                class="form-control"
                id="email"
                formControlName="email"
                autocomplete="false"
                [ngClass]="getClassName('email', 'inputClass')"
              />
              @if (createClientFormControl?.email?.invalid && (createClientFormControl?.email?.dirty || createClientFormControl?.email?.touched)) { 
                @if (createClientFormControl?.email?.errors?.required) {
                <div class="invalid-feedback">Email is required.</div>
                } 
                @if (createClientFormControl?.email?.errors?.pattern) {
                <div class="invalid-feedback">Please enter a valid email.</div>
                } 
            }
            @if(createClientFormControl?.email?.errors?.serverError) {
              <div class="invalid-feedback">{{createClientFormControl?.email?.errors?.serverError}}</div>
            }
            </div>
            <div class="col-6 mb-3">
              <label for="firstName" class="form-label"
                >Password <span class="text-muted required">*</span></label
              >
              <show-hide-password class="show-hide-btn" [btnOutline]="false">
              <input
                type="password"
                class="form-control"
                id="password"
                formControlName="password"
                autocomplete="false"
                [ngClass]="getClassName('password', 'inputClass')"
              />
            </show-hide-password>
              @if (createClientFormControl?.password?.invalid && (createClientFormControl?.password?.dirty || createClientFormControl?.password?.touched)) { 
                @if (createClientFormControl?.password?.errors?.required) {
                <div class="invalid-feedback">Password is required.</div>
                } 
                @if (createClientFormControl?.password?.errors?.minLength) {
                    <div class="invalid-feedback">Password min length is 8 char.</div>
                }
                @if (createClientFormControl?.password?.errors?.maxLength) {
                    <div class="invalid-feedback">Password max length is 64 char.</div>
                } 
                @if (createClientFormControl?.password?.errors?.pattern) {
                <div class="invalid-feedback">Please enter a valid password.</div>
                } 
              }
              @if(createClientFormControl?.password?.errors?.serverError) {
                <div class="invalid-feedback">{{createClientFormControl?.password?.errors?.serverError}}</div>
              }
              @if (createClientFormControl?.password?.dirty) {
                  <div class="col-12 mb-3">
                      <label class="password-requirements">Password Requirements:</label>
                      <ul class="password-requirement">
                          <li [ngClass]="createClientFormControl?.password?.errors?.required || createClientFormControl?.password.errors?.hasSmallCase?'password-requirement-invalid':'password-requirement-valid'">
                              At least one lowercase letter (a-z).
                          </li>
                          <li [ngClass]="createClientFormControl?.password?.errors?.required || createClientFormControl?.password.errors?.hasCapitalCase?'password-requirement-invalid':'password-requirement-valid'">
                              At least one UPPERCASE letter (A-Z).
                          </li>
                          <li [ngClass]="createClientFormControl?.password?.errors?.required || createClientFormControl?.password.errors?.hasNumber?'password-requirement-invalid':'password-requirement-valid'">
                              At least one number (0-9).
                          </li>
                          <li
                              [ngClass]="createClientFormControl?.password?.errors?.required || createClientFormControl?.password.errors?.hasSpecialCharacters?'password-requirement-invalid':'password-requirement-valid'">
                              At least one special character.
                          </li>
                          <li
                              [ngClass]="createClientFormControl?.password?.errors?.required || (createClientFormControl?.password.errors?.minlength || createClientFormControl?.password.errors?.maxlength)?'password-requirement-invalid':'password-requirement-valid'">
                              Has length of 8-64 characters.
                          </li>
                      </ul>
                  </div>
              
                }
            </div>
          </div>
          
          <button
            class="w-100 btn btn-lg btn-primary sign-in-button"
            type="submit"
            [disabled]="createClientForm.invalid"
          >
            Create account
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
