<main class="app-main">
    <section class="app-banner">
        <div class="row">
            <div class="col-md-12">
                <h3 class="choose-a-plan-text">Sorry to see you go</h3>
                <p class="text-note-for-cancel" *ngIf="isPaused && currentPlanStatus === 'active'">Your {{companyName}}’s subscription is
                    <span class="text-strong">Paused</span>.
                </p>
                <p class="text-note-for-cancel" *ngIf="isPaused && currentPlanStatus === 'canceled'">Your {{companyName}}’s subscription is
                    <span class="text-strong">Canceled</span>.
                </p>
                <p class="text-note-for-cancel" *ngIf="!isPaused">Your {{companyName}} subscription is
                    <span class="text-strong">{{currentPlanStatus | lowercase}}</span>. <br>
                    You can pause your subscription instead of canceling, or <br>
                    you can <a class="hyper-link" href="mailto:{{supportEmail}}">contact</a> our team so we can find
                    a better solution for you.
                </p>
            </div>
            <div class="col-md-12 mt-4">
                <div class="row">
                    <div class="col-md-4">
                        <div class="item text-center">
                            <img class="img-responsive" src="assets/images/support.svg">
                            <p class="item-text">
                                Let us know how we can make {{companyName}} work better for your team.
                            </p>
                            <a class="btn" href="mailto:{{supportEmail}}">Contact support</a>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="!isPaused">
                        <div class="item text-center">
                            <img class="img-responsive" src="assets/images/pause-subscription.svg">
                            <p class="item-text">
                                Pause your subscription for 30 days. We'll let you know when it will be active again.
                            </p>
                            <button class="btn" [swal]="{ 
                                icon: 'question',
                                title: 'Pause subscription', 
                                text: 'Are you sure you want to pause your subscription?',
                                showCancelButton: false,
                                confirmButtonText: 'Yes, continue',
                                showDenyButton: true,
                                denyButtonText: 'No, cancel',
                                reverseButtons: true
                            }" (confirm)="pauseSubscription()">Pause subscription</button>
                        </div>
                    </div>
                    <div class="col-md-4" *ngIf="isPaused">
                        <div class="item text-center">
                            <img class="img-responsive" src="assets/images/resume-subscription.svg">
                            <p class="item-text">
                                Your subscription will automatically resume {{resumeAt |
                                date: 'MMMM d, YYYY'}}. If you want resume immediately, click the "Resume subscription"
                                button.
                            </p>
                            <button class="btn" [swal]="{ 
                                icon: 'question',
                                title: 'Resume subscription',
                                text: 'Are you sure you want to resume your subscription?',
                                showCancelButton: false,
                                confirmButtonText: 'Yes, continue',
                                showDenyButton: true,
                                denyButtonText: 'No, cancel',
                                reverseButtons: true
                            }" (confirm)="resumeSubscription()">Resume subscription</button>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item text-center">
                            <img class="img-responsive" src="assets/images/close.svg">
                            <p class="item-text">
                                Cancel your {{companyName}} subscription.
                            </p>
                            <button class="btn" (click)="openCancelSubscriptionPopup()">Close account</button>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-12">
                        <a class="back-button float-right" [routerLink]="['/billing']"
                            routerLinkActive="router-link-active">Go back</a>
                    </div>
                </div>

            </div>

        </div>
    </section>
</main>