import { Component } from '@angular/core';
import { environment } from '@env/environment';
import {RouterModule} from '@angular/router';
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  copyrightTerms: string = `©  ${new Date().getFullYear()} ${environment.companyName}`;
}
