
<div class="text-center">
    <img class="mb-4 login-logo" src="assets/register-popup-logo.png" alt="" width="64" height="64"/>
</div>
<h1 class="h3 mb-3 login-heading">Please check your email</h1>
<div class="col-12 mb-3">
    <div class="field mb-2">
        <p>You have requested a password reset. To get a new password, please check your email
            for a message from <a href="mailto:{{noReplyEmail}}">{{companyName}}</a> and enter the code we have sent to: <span class="bold">{{email()}}</span>.</p>
        <button class="button" type="button" class="btn btn-primary" (click)="changeEmail()">Change email</button>
    </div>
    <div class="field mb-2">
        <label for="email" class="mb-2">Password reset code <span class="required">*</span></label>
        <code-input [isCodeHidden]="false" [codeLength]="6" (codeCompleted)="onCodeCompleted($event)"  style="font-size:24px" formControlName="code">
        </code-input>
    </div>
    <div class="field mt-3 mb-3">
        <p>
            Please check your spam folder if you do not see the password reset email in
            your inbox. If you still do not see it, click "Resend code."
        </p>
    </div>
</div>
<button class="btn btn-primary float-start" type="button" (click)="resendPasswordResetEmail()">Resend code</button>