import { Component } from '@angular/core';

@Component({
  selector: 'app-term-of-service',
  standalone: true,
  imports: [],
  templateUrl: './term-of-service.component.html',
  styleUrl: './term-of-service.component.scss'
})
export class TermOfServiceComponent {

}
