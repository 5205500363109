import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MaterialModule } from '@material/material.module';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { QuickbooksService } from '@services/quickbooks.service';
import { Subject, catchError, filter, takeUntil, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ExpensesAccountPopupComponent } from '@controllers/private/integrations/quick-books/expenses-account-popup/expenses-account-popup.component';

@Component({
  selector: 'app-quick-books',
  standalone: true,
  imports: [CommonModule, MaterialModule, RouterModule, MatDialogModule],
  templateUrl: './quick-books.component.html',
  styleUrl: './quick-books.component.scss'
})
export class QuickBooksComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  integration: string;
  qboCompanyId: string;
  qboCompanyName: string;
  latestSuccessSyncLogsData: any;
  lastSuccessSync: any;
  runningSync: any;

  isQBOConnected = false;
  isAccountsLoading = false;
  isAmountSpentOnMarketingLoading = false;
  isReportsLoading = false;
  constructor(
    private quickBooksService: QuickbooksService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastrService,
    private dialog: MatDialog
  ) {
    this.integration = this.activatedRoute.snapshot?.data?.['integration'];
    this.qboCompanyId = '';
    this.qboCompanyName = '';
    this.latestSuccessSyncLogsData = null;
    this.isQBOConnected = false;
    this.runningSync = null;
    this.lastSuccessSync = null;
  }

  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((data: any) => {
      this.isQBOConnected = data?.integrations?.data?.isQBOConnected;
    });
    this.refreshQuickBooksDetails();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  refreshQuickBooksDetails(): void {
    this.quickBooksService.getQuickBooksDetails()
    .pipe(
      filter(response => response?.success),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response: any) => {
      this.qboCompanyId = response?.data?.companyId;
      this.qboCompanyName = response?.data?.companyName;
      this.latestSuccessSyncLogsData = response?.data?.latestSuccessSyncLogData;
      this.lastSuccessSync = response?.data?.lastSuccessSync;
      this.runningSync = response?.data?.runningSyncLogData;
      this.checkRunningSyncs();
    });
  }

  connectQBO(): void {
    this.quickBooksService.connectQBO()
    .pipe(
      filter(response => response?.success),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((response: any) => {
      window.location.replace(response?.data?.redirectUrl);
    });
  }

  disconnectQBO(): void {
    this.quickBooksService.disconnectQBO()
    .pipe(
      filter(response => response?.success),
      catchError((error: any) => {
        this.toastService.error('something went wrong please try again.', 'Disconnect QuickBooks');
        return throwError(error);
      }),
      takeUntil(this.componentDestroyed$)
    ).subscribe((response: any) => {
      this.isQBOConnected = false;
      this.toastService.success(response?.message, response?.title);
      this.refreshQuickBooksDetails();
    });
  }

  syncQBOReports(): void {
    this.isReportsLoading = true;
    this.quickBooksService.syncQBOReports().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((_response: any) => {
      this.isReportsLoading = false;
      if(_response?.success) {
        this.toastService.success(_response?.message, _response?.title);
      } else {
        if(_response?.message === 'The refresh token has expired. Please reconnect to QuickBooks.') this.isQBOConnected = false;
        this.toastService.error(_response?.message, _response?.title);
      }
    });
  }

  syncQBOAmountSpentOnMarketing(): void {
    this.isAmountSpentOnMarketingLoading = true;
    this.quickBooksService.syncQBOAmountSpentOnMarketing().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((_response: any) => {
      this.isAmountSpentOnMarketingLoading = false;
      if(_response?.success) {
        this.toastService.success(_response?.message, _response?.title);
      } else {
        if(_response?.message === 'The refresh token has expired. Please reconnect to QuickBooks.') this.isQBOConnected = false;
        this.toastService.error(_response?.message, _response?.title);
      }
    });
  }

  syncQBOAccounts(): void {
    this.isAccountsLoading = true;
    this.quickBooksService.syncQBOAccounts().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((_response: any) => {
      this.isAccountsLoading = false;
      this.refreshQuickBooksDetails();
      if(_response?.success) {
        this.toastService.success(_response?.message, _response?.title);
      } else {
        if(_response?.message === 'The refresh token has expired. Please reconnect to QuickBooks.') this.isQBOConnected = false;
        this.toastService.error(_response?.message, _response?.title);
      }
    });
  }

  onCancel(syncType: string): void {
    if (this.runningSync[syncType] && this.runningSync[syncType]['status'] === 'STARTED') {
      if (confirm('Are you sure you want to forcefully stop this sync process?')) {
        this.quickBooksService.stopSync({logId: this.runningSync[syncType]['logId'], type: this.runningSync[syncType]['syncedDetails']['dataType']})
        .pipe(
          filter(res => res.success),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe((response: any) => {
          this.toastService.success(response?.message, response?.title);
          this.isReportsLoading = false;
          this.isAccountsLoading = false;
          this.isAmountSpentOnMarketingLoading = false;
        });
      } else {
        console.log('Thing was not saved to the database.');
      }
    }
  }


  checkRunningSyncs(): void {
    this.isReportsLoading = !!this.runningSync?.reports;
    this.isAccountsLoading = !!this.runningSync?.accounts;
    this.isAmountSpentOnMarketingLoading = !!this.runningSync?.amountSpentOnMarketing;
  }

  openExpensesAccountPopup(): void {
    const dialogRef = this.dialog.open(ExpensesAccountPopupComponent);
    dialogRef.afterClosed().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
