import { Component, OnDestroy, ViewChild, signal } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { RouterModule, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, catchError, takeUntil, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';
import { VerifyForgotPasswordEmailComponent } from '@controllers/public/forgot-password/verify-forgot-password-email/verify-forgot-password-email.component';
import { ResetPasswordComponent } from '@controllers/public/forgot-password/reset-password/reset-password.component';
import { FORGOT_PASSWORD_STEP } from '@models/forgot.password.step.enum';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RouterModule, NgxCaptchaModule, VerifyForgotPasswordEmailComponent, ResetPasswordComponent],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
  providers: [HttpClientModule],
})
export class ForgotPasswordComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  forgotPasswordForm: FormGroup;
  captchaCode = '';
  captchaSiteKey = environment.CAPTCHA_SITE_KEY;
  captchaSettingLang = 'en';
  captchaSettingGlobalDomain = false;
  currentStep: FORGOT_PASSWORD_STEP;
  SEND_VERIFICATION_EMAIL = FORGOT_PASSWORD_STEP.SEND_VERIFICATION_EMAIL;
  VERIFY_EMAIL_ADDRESS = FORGOT_PASSWORD_STEP.VERIFY_EMAIL_ADDRESS;
  RESET_PASSWORD = FORGOT_PASSWORD_STEP.RESET_PASSWORD;
  email = signal<string>('');
  token = signal<string>('');
  @ViewChild('captchaElem', { static: false }) captchaElem!: ReCaptcha2Component;

  constructor(
    private authService: AuthService,
    private toastService: ToastrService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router
    ) {
    this.currentStep = this.SEND_VERIFICATION_EMAIL;
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(environment.EMAIL_REGEX)]),
      recaptcha: new FormControl('', [Validators.required])
    });
  }

  get forgotPasswordFormControls(): any {
    return this.forgotPasswordForm.controls;
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  updateCurrentStep(step: FORGOT_PASSWORD_STEP): void {
    this.currentStep = step;
  }

  updateData({email, token}: {email: string, token: string}): void {
    this.token.update(() => token);
    this.email.update(() => email);
  }


  submit(): void {
    if(this.forgotPasswordForm.valid) {
      const request = {
        email: this.forgotPasswordForm?.controls['email'].value?.toLowerCase()?.trim(),
        recaptcha: this.forgotPasswordForm?.controls['recaptcha'].value?.trim()
      }
      this.authService.sendRecoveryEmail(request)
      .pipe(
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.forgotPasswordForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          this.captchaElem.resetCaptcha();
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response: any) => {
        if(response?.success) {
          this.toastService.success(response?.message, response?.title);
          this.token.set(response?.data?.token);
          this.email.set(response?.data?.email);
          this.currentStep = this.VERIFY_EMAIL_ADDRESS;
        } else {
          this.captchaElem.resetCaptcha();
          this.toastService.error(response?.message, response?.title);
        }
      });
    }
  }

  handleCaptchaReset(): any {}

  handleCaptchaExpire(): any {}

  handleCaptchaLoad(): any {}

  handleCaptchaSuccess($event: any): void {
    this.captchaCode = $event;
  }
}
