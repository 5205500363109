<div class="integrations pb-4">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h4 class="title">Integrations with {{integrationParty}}</h4>
        <h6 class="subtitle">Check your {{integrationParty}} Integrations Data</h6>
      </div>
      <div class="col-md-12">
        <div class="integration-tabs row">
          <div class="integration-menu col-md-2">
            <mat-list class="app-toolbox-menu-list">
              <h3 mat-subheader>GENERAL</h3>
              <mat-list-item (click)="onTabChange(syncNow)" [ngClass]="{ active: currentTab === syncNow }">
                <mat-icon mat-list-icon>apps</mat-icon>
                <h4 mat-line>Sync Now</h4>
              </mat-list-item>
              <mat-list-item (click)="onTabChange(syncLogs)" [ngClass]="{ active: currentTab === syncLogs }">
                <mat-icon mat-list-icon>update</mat-icon>
                <h4 mat-line>Sync Logs</h4>
              </mat-list-item>
              <!-- <mat-list-item (click)="onTabChange(syncErrorLogs)" [ngClass]="{ active: currentTab === syncErrorLogs }">
                <mat-icon mat-list-icon>error</mat-icon>
                <h4 mat-line>Sync Error Logs</h4>
              </mat-list-item> -->
            </mat-list>
          </div>
          <div fxFlex class="app-toolbox-body p-4 col-md-10">
            <div class="">
              <div *ngIf="currentTab === syncNow" class="row app-sync-tab mt-3">
                <router-outlet></router-outlet>
              </div>
              <div *ngIf="currentTab === syncLogs" class="app-logs-table">
                <app-sync-log [code]="integrationCode()"></app-sync-log>
              </div>
              <!-- <div *ngIf="currentTab === syncErrorLogs" class="app-logs-table">
                <app-sync-error-log></app-sync-error-log>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
