import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, Output, input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomValidators } from '@app/helpers/custom-validators';
import { REGISTER_STEP } from '@app/models/register.step.enum';
import { AuthService } from '@app/services/auth.service';
import { environment } from '@env/environment';
import { Subject, catchError, filter, takeUntil, throwError } from 'rxjs';
import { ShowHideInputDirective, ShowHidePasswordComponent, ShowHideStatusDirective, ShowHideTriggerDirective } from 'ngx-show-hide-password';
import { CountryType } from '@app/types/country.type';
import { ToastrService } from 'ngx-toastr';
import { CountriesApiResponse } from '@app/types/countries.api.response.type';
import { NewUserData, NewUserDetailsApiResponse } from '@app/types/new.user.details.api.response';

@Component({
  selector: 'app-new-user-details',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, ShowHidePasswordComponent, ShowHideInputDirective, ShowHideStatusDirective, ShowHideTriggerDirective],
  templateUrl: './new-user-details.component.html',
  styleUrl: './new-user-details.component.scss'
})
export class NewUserDetailsComponent implements OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  @Output() updateStep = new EventEmitter<REGISTER_STEP>();
  @Output() updateData = new EventEmitter<NewUserData>();
  selectedPlan = input.required<any>();
  selectedPaymentType = input.required<string>();
  registerForm: FormGroup;
  countries: CountryType[];
  constructor(
    private formBuild: FormBuilder,
    private authService: AuthService,
    private toastService: ToastrService
  ) {
    this.countries = [];
    this.authService.getCountries()
    .pipe(
      filter(response => !!(response?.success && response?.data?.countries)),
      takeUntil(this.componentDestroyed$))
    .subscribe((result: CountriesApiResponse) => {
      this.countries = result.data.countries;
    });
    this.registerForm = this.formBuild.group({
      companyName: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.pattern(environment.NAME_REGEX)]],
      lastName: ['', [Validators.required, Validators.pattern(environment.NAME_REGEX)]],
      email: ['', [Validators.required, Validators.pattern(environment.EMAIL_REGEX)]],
      phone: ['', [Validators.required]],
      country: ['United States', [Validators.required]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(/[~@#$%^&*+=_`|{}:;!.?"()[]/ , { hasSpecialCharacters: true })
      ]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: CustomValidators.passwordMatchValidator
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  get registerFormControls(): any {
    return this.registerForm.controls;
  }

  submitHandler(): void {
    if(this.registerForm.valid) {
      const request = {
        email: this.registerForm?.controls['email'].value?.toLowerCase()?.trim(),
        firstName: this.registerForm?.controls['firstName'].value?.trim(),
        lastName: this.registerForm?.controls['lastName'].value?.trim(),
        companyName: this.registerForm?.controls['companyName'].value?.trim(),
        country: this.registerForm?.controls['country'].value?.trim(),
        phone: this.registerForm?.controls['phone'].value?.trim(),
        password: this.registerForm?.controls['password'].value?.trim(),
        confirmPassword: this.registerForm?.controls['confirmPassword'].value?.trim()
      }
      this.authService.saveNewUserDetails(request)
      .pipe(
        catchError((error: any) => {
          if(error instanceof HttpErrorResponse) {
            if (error.status === 422 && error?.error?.errors) {
              for (const err of error.error.errors) {
                const formControl = this.registerForm.get(err?.path);
                if (formControl) {
                  formControl.setErrors({
                    serverError: err?.msg
                  });
                }
              }
           }
          }
          return throwError(error);
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((response: NewUserDetailsApiResponse) => {
        if(response?.success) {
          this.toastService.success(response?.message, response?.title);
          this.setData(response?.data)
          this.stepNext();
        } else {
          this.toastService.error(response?.message, response?.title);
        }
      });
    }
  }

  stepBack(): void {
    this.updateStep.emit(REGISTER_STEP.SELECT_PLAN);
  }

  stepNext(): void {
    this.updateStep.emit(REGISTER_STEP.VERIFY_EMAIL);
  }

  setData(data: NewUserData): void {
    this.updateData.emit(data);
  }
}
