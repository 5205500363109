<div class="text-center">
    <img class="mb-4 login-logo" src="assets/register-popup-logo.png" alt="" width="64" height="64"/>
</div>
<h1 class="h3 mb-3 login-heading">Set a new password</h1>
<form [formGroup]="passwordForm" (ngSubmit)="resetPassword()">
    <div class="col-12 mb-3">
        <label for="email">New password <span class="required">*</span></label>
        <show-hide-password class="show-hide-btn" [btnOutline]="false">
            <input class="form-control" id="newPassword" name="newPassword" type="password" placeholder="new password" value="" formControlName="newPassword">
        </show-hide-password>
        @if(passwordControls?.newPassword?.invalid && (passwordControls?.newPassword?.dirty || passwordControls?.newPassword?.touched)) {
            @if(passwordControls?.newPassword?.errors?.required) {
                <div class="invalid-feedback">New password is required</div>
            }
        }
    </div>
    <div class="col-12 mb-3">
        <label for="email">Confirm password <span class="required">*</span></label>
        <show-hide-password class="show-hide-btn" [btnOutline]="false">
            <input class="legacy-styling-input" id="confirmPassword" name="confirmPassword" type="password" placeholder="confirm password" value="" formControlName="confirmPassword">
        </show-hide-password>
        @if(passwordControls?.confirmPassword?.invalid && (passwordControls?.confirmPassword?.dirty || passwordControls?.confirmPassword?.touched)) {
            @if(passwordControls?.confirmPassword?.errors?.required) {
                <div class="invalid-feedback">Confirm password is required</div>
            }
        }
    </div>
    @if(passwordControls.newPassword.dirty || passwordControls.confirmPassword.dirty) {
        <div class="col-12 mb-3">
            <label class="password-requirements">Password Requirements:</label>
            <ul class="password-requirement">
                <li [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasSmallCase?'password-requirement-invalid':'password-requirement-valid'">
                    At least one lowercase letter (a-z).
                </li>
                <li [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasCapitalCase?'password-requirement-invalid':'password-requirement-valid'">
                    At least one UPPERCASE letter (A-Z).
                </li>
                <li [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasNumber?'password-requirement-invalid':'password-requirement-valid'">
                    At least one number (0-9).
                </li>
                <li
                    [ngClass]="(passwordControls || null)?.newPassword?.errors?.required || passwordControls.newPassword.errors?.hasSpecialCharacters?'password-requirement-invalid':'password-requirement-valid'">
                    At least one special character.
                </li>
                <li
                    [ngClass]="passwordControls?.newPassword?.errors?.required || (passwordControls.newPassword.errors?.minlength || passwordControls.newPassword.errors?.maxlength)?'password-requirement-invalid':'password-requirement-valid'">
                    Has length of 8-64 characters.
                </li>
                <li
                    [ngClass]="passwordControls?.newPassword?.errors?.required || passwordControls.confirmPassword.errors?.NoPasswordMatch?'password-requirement-invalid':'password-requirement-valid'">
                    Please confirm your password.
                </li>
            </ul>
        </div>
    }
    <div class="auth-controls">
        <button class="btn btn-primary float-end" type="submit" [disabled]="passwordForm.invalid">Save</button>
    </div>
</form>