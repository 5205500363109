<form name="account" novalidate [formGroup]="cancelSubForm" (ngSubmit)="cancelSubscription()">
    <div class="modal-body">
        <div class="mb-4">
            <h4 class="choose-a-plan-text">OK, we'll close your account</h4>
        </div>
        <div class="form-group col-md-8 p-0">
            <label>Why are you leaving?</label>
            <select class="form-control legacy-styling-input" type="select" formControlName="leavingReason">
                <option *ngFor="let leaving of leavingOptions" [value]="leaving.value">{{leaving.name}}</option>
            </select>
            <div class="error-message" *ngIf="(cancelSubFormControls?.leavingReason?.dirty ||
            cancelSubFormControls?.leavingReason?.touched)">
                <div *ngIf="cancelSubFormControls?.leavingReason?.errors?.required">
                    Reason for leaving is required.
                </div>
            </div>
        </div>
        <div class="form-group col-md-8 p-0">
            <label>What can we improve?</label>
            <textarea rows="3" class="form-control legacy-styling-input" formControlName="improvement"></textarea>
            <div class="error-message" *ngIf="(cancelSubFormControls?.improvement?.dirty ||
            cancelSubFormControls?.improvement?.touched)">
                <div *ngIf="cancelSubFormControls?.improvement?.errors?.required">
                    Your feedback is required.
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer btn-group mt-3">
        <div class="col-md-6 p-0">
            <button type="submit" class="btn float-right btn-primary me-2" [disabled]="cancelSubForm.invalid">Submit</button>
            <button type="button" class="btn btn-primary float-right" (click)="closeDialogPopup()">Cancel</button>
        </div>

    </div>
</form>