<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-3 footer-logo">
        <img src="assets/logo_white.svg" class="company-logo" />
      </div>
      <div class="col-md-6 copyright align-self-center">
        {{ copyrightTerms }}
      </div>
      <div class="col-md-3 service-links align-self-center">
        <a href="#" [routerLink]="['/privacy-policy']" routerLinkActive="router-link-active"  class="pe-2"> Privacy Policy </a>
        <a href="#" [routerLink]="['/term-of-service']" routerLinkActive="router-link-active" > Term of service</a>
      </div>
    </div>
  </div>
</footer>
